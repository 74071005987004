import React from 'react';
import css from './SearchInputStyle.module.css';
import IconMap from '../../../../../components/IconMap/IconMap';
import ButtonComponent from '../../../ButtonComponent/ButtonComponent';



import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { useConfiguration } from '../../../../../context/configurationContext';
import { useRouteConfiguration } from '../../../../../context/routeConfigurationContext';
import { isMainSearchTypeKeywords, isOriginInUse } from '../../../../../util/search';
import { parse } from '../../../../../util/urlHelpers';
import TopbarSearchForm from '../../../../TopbarContainer/Topbar/TopbarSearchForm/TopbarSearchForm';
import { createResourceLocatorString } from '../../../../../util/routes';

const SearchInputMap = () => {

  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();

  const { keywords, address, origin, bounds } = parse(location.search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });

  const topbarSearcInitialValues = () => {
    if (isMainSearchTypeKeywords(config)) {
      return { keywords };
    }

    const locationFieldsPresent = isOriginInUse(config)
      ? address && origin && bounds
      : address && bounds;
    return {
      location: locationFieldsPresent
        ? {
            search: address,
            selectedPlace: { address, origin, bounds },
          }
        : null,
    };
  };

  const initialSearchFormValues = topbarSearcInitialValues();

  function handleSubmit(values) {
    const topbarSearchParams = () => {
      if (isMainSearchTypeKeywords(config)) {
        return { keywords: values?.keywords };
      }

      const { search, selectedPlace } = values?.location;
      const { origin, bounds } = selectedPlace;
      const originMaybe = isOriginInUse(config) ? { origin } : {};


      return {
        ...originMaybe,
        address: search,
        bounds,
      };
    };
    const searchParams = {
      ...topbarSearchParams(),
    };
    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, searchParams));
  }

  return (
    <>
      <div className={css.wrapperSearch}>
        <div className={css.searchinput}>
          <div className={css.formField}>
       
            <span className={css.icon}>
            

              <IconMap />
            </span>
            <TopbarSearchForm
                      onSubmit={handleSubmit}
                      initialValues={initialSearchFormValues}
                      appConfig={config}
                    />
            {/* <input type="text" value={''} placeholder="Search location" /> */}
          </div>
          <ButtonComponent
            value="Search"
            type="submit"
            customClass="customClass"
            variant="primary"
          />
        </div>
      </div>
    </>
  );
};

export default SearchInputMap;
