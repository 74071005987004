import React from 'react';
import pic1 from '../Assets/pic1.png';
import pic2 from '../Assets/pic2.png';
import pic3 from '../Assets/pic3.png';
import pic4 from '../Assets/pic4.png';
import pic5 from '../Assets/pic5.png';
import css from './FeaturedListings.module.css';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const ListingCard = ({ image, title, location, category }) => {
  return (
    <div className={css.listingCard}>
      <div className={css.mediaHolder}>
        <img src={image} alt={title} className={css.listingImage} />
      </div>
      <span className={css.listingCategory}>{category}</span>
      <h3 className={css.listingTitle}>{title}</h3>
      <p className={css.listingLocation}>{location}</p>
      <button className={`${css.viewDetailsButton} btnBlock btnOutline btnDefault`}>
        {category === 'Assisted Living' ? 'View Details' : 'More Info'}
      </button>
    </div>
  );
};

const FeaturedListings = () => {
  const intl = useIntl();
const history=useHistory()

  const listings = [
    {
      image: pic1,
      category: intl.formatMessage({ id: 'CustomHomePage.MemoryCare' }),
      title: 'Charter Senior Living Of ...',
      location: 'Atlanta, GA',
    },
    {
      image: pic2,
      category: intl.formatMessage({ id: 'CustomHomePage.AssistedLiving' }),
      title: 'Doliver of Tanglewood',
      location: 'Atlanta, GA',
    },
    {
      image: pic3,
      category: intl.formatMessage({ id: 'CustomHomePage.IndependentLiving' }),
      title: 'Corso Atlanta',
      location: 'Atlanta, GA',
    },
    {
      image: pic4,
      category: intl.formatMessage({ id: 'CustomHomePage.RespiteCare' }),
      title: 'Clearwater at the Heights',
      location: 'Atlanta, GA',
    },
    {
      image: pic5,
      category: intl.formatMessage({ id: 'CustomHomePage.MemoryCare' }),
      title: 'The Piedmont at Buckhead',
      location: 'Atlanta, GA',
    },
    {
      image: pic5,
      category: intl.formatMessage({ id: 'CustomHomePage.AssistedLiving' }),
      title: 'The Piedmont at Buckhead',
      location: 'Atlanta, GA',
    },
  ];

  return (
    <section className={css.featuredListings}>
      <div className="container">
        <div className={css.featureWrapper}>
          <div className={`${css.sectionHeader} textCenter smallWidth`}>
            <h2>{intl.formatMessage({ id: 'CustomHomePage.FeaturedListingsTitle' })}</h2>
            <p>{intl.formatMessage({ id: 'CustomHomePage.FeaturedListingsDescription' })}</p>
          </div>
          <div className={css.listingsGrid}>
            {listings.map((listing, index) => (
              <ListingCard
                key={index}
                image={listing.image}
                category={listing.category}
                title={listing.title}
                location={listing.location}
              />
            ))}
          </div>
          <div className={`${css.viewMoreWrapper} textCenter`}>
            <button className={`${css.viewMoreButton} btnDefault`}             onClick={()=> history.push('/s')}
             >
              {intl.formatMessage({ id: 'CustomHomePage.ViewMoreButton' })}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturedListings;
