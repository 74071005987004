import React from 'react';
import { LayoutSingleColumn, Page } from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';
import BannerComp from './BannerComp/BannerComp';
import CareCommunityOptions from './CareCommunityOptions/CareCommunityOptions';
import BlogSection from './BlogSection/BlogSection';
import HowWeHelp from './HowWeHelp/HowWeHelp';
import FeaturedListings from './FeaturedListings/FeaturedListings';
import FAQSection from './FAQSection/FAQSection';
import LocationsSection from './LocationsSection/LocationsSection';
import css from './LandingPageCutom.module.css';
import CareAndConnectionSection from './CareAndConnectionSection/CareAndConnectionSection';
import loadable from '@loadable/component';
import GoogleTagManager from '../../components/GoogleTagManager/GoogleTagManager';
const TestimonialsSection = loadable(() => import('./TestimonialsSection/TestimonialsSection'), {
  ssr: false,
  fallback: <p>loading..............</p>,
});
const LandingPageCustom = () => {
  return (
    <Page title={''} scrollingDisabled={false}>
      <GoogleTagManager />
      <div className={css.customLandingMain}>
        <LayoutSingleColumn topbar={<TopbarContainer />} footer={<FooterContainer />}>
          <BannerComp />
          <CareCommunityOptions />
          <FeaturedListings />
          <HowWeHelp />
          <LocationsSection />
          <TestimonialsSection />
          <BlogSection />
          <FAQSection />
          <CareAndConnectionSection />
        </LayoutSingleColumn>
      </div>
    </Page>
  );
};
export default LandingPageCustom;
