function getBounds(origin, radius) {
  const earthRadius = 6378; // Earth's radius in kilometers
  const lat = origin.lat * (Math.PI / 180); // Convert latitude to radians
  const lon = origin.lng * (Math.PI / 180); // Convert longitude to radians
  const latOffset = radius / earthRadius;
  const lonOffset = radius / (earthRadius * Math.cos(lat));

  return {
    northEast: {
      lat: (lat + latOffset) * (180 / Math.PI),
      lng: (lon + lonOffset) * (180 / Math.PI),
    },
    southWest: {
      lat: (lat - latOffset) * (180 / Math.PI),
      lng: (lon - lonOffset) * (180 / Math.PI),
    },
  };
}

// Function to calculate combined bounds for "multiple miles"
function getCombinedBounds(origin, radiusInKm) {
  const lowerBound = getBounds(origin, radiusInKm[0]);
  const upperBound = getBounds(origin, radiusInKm[1]);

  return {
    northEast: {
      lat: Math.max(lowerBound.northEast.lat, upperBound.northEast.lat),
      lng: Math.max(lowerBound.northEast.lng, upperBound.northEast.lng),
    },
    southWest: {
      lat: Math.min(lowerBound.southWest.lat, upperBound.southWest.lat),
      lng: Math.min(lowerBound.southWest.lng, upperBound.southWest.lng),
    },
  };
}

// Function to perform search based on selected radius
function performSearch(selectedRadius, userLocation, radiusInKm, filterKey) {
  if (userLocation) {
    const query = { origin: userLocation, bounds: null };

    if (Array.isArray(radiusInKm)) {
      const combinedBounds = getCombinedBounds(userLocation, radiusInKm);
      query.bounds = `${combinedBounds.northEast.lat},${combinedBounds.northEast.lng},${combinedBounds.southWest.lat},${combinedBounds.southWest.lng}`;
    } else {
      const bounds = getBounds(userLocation, radiusInKm);
      query.bounds = `${bounds.northEast.lat},${bounds.northEast.lng},${bounds.southWest.lat},${bounds.southWest.lng}`;
    }

    return {
      origin: `${query.origin?.lat},${query.origin?.lng}`,
      bounds: `${query.bounds}`,
      [filterKey]: selectedRadius,
    };
  }
}

export { performSearch, getCombinedBounds, getBounds };
