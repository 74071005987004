import React from 'react'
import css from './HeadingStyle.module.css'
import classNames from 'classnames'
const HeadingComponent = ({title, subTitle, mainTitle, highlightText, className,isCustomClass=false}) => {
  return (
    <div className={classNames(css.headingBlock,{[css.customclass]: isCustomClass })} >
        <p>{mainTitle}</p>
        <h2>{title} <span> {highlightText}</span></h2> 
        <p>{subTitle}</p>
        {className}
    </div>
  )
}

export default HeadingComponent
