import React from 'react';
import careAsst from '../Assets/careAsst.png'
import css from './CareAsstment.module.css'
import { PageTitleRev,PageTitle } from '../../OtherPagesComponents/PageHeadingTitle/PageTitle';
import { useIntl } from 'react-intl';

const CareAsstment = () => {
    const intl = useIntl()
    const CareAsstmentTitle = intl.formatMessage({id:'ResourcePage.CareAsstmentTitle'})
    const CareAsstmentHighLitedText = intl.formatMessage({id:'ResourcePage.CareAsstmentHighLitedText'})
    const CareAsstmentDescription = intl.formatMessage({id:'ResourcePage.CareAsstmentDescription'})
    const CareAsstmentBannerDescription = intl.formatMessage({id:'ResourcePage.CareAsstmentBannerDescription'})
    const CareAsstmentDataSection = intl.formatMessage({id:'ResourcePage.CareAsstmentDataSection'})
    const CareAsstmentDataSection1 = intl.formatMessage({id:'ResourcePage.CareAsstmentDataSection1'})
    const CareAsstmentDataSection2 = intl.formatMessage({id:'ResourcePage.CareAsstmentDataSection2'})
    const CareAsstmentDataSection3 = intl.formatMessage({id:'ResourcePage.CareAsstmentDataSection3'})
    const CareAsstmentDataSection4 = intl.formatMessage({id:'ResourcePage.CareAsstmentDataSection4'})
    const CareAsstmentDataSection5 = intl.formatMessage({id:'ResourcePage.CareAsstmentDataSection5'})
    const CareAsstmentDataSection6 = intl.formatMessage({id:'ResourcePage.CareAsstmentDataSection6'})
    const CareAsstmentDataSection7 = intl.formatMessage({id:'ResourcePage.CareAsstmentDataSection7'})
    const CareAsstmentDescriptionSection = intl.formatMessage({id:'ResourcePage.CareAsstmentDescriptionSection'})
    const CareAsstmentButton = intl.formatMessage({id:'ResourcePage.CareAsstmentButton'})


const arr =[
    CareAsstmentDataSection,CareAsstmentDataSection1,CareAsstmentDataSection2,CareAsstmentDataSection3,
    CareAsstmentDataSection4,CareAsstmentDataSection5,CareAsstmentDataSection6,CareAsstmentDataSection7
]
    return(
        <div className={css.resourcesToolsBlock}>
            <div className={css.imageColumn}>
                <div className={css.imageBlock}>
                         <img src={careAsst} alt="test" className='care-asst-img'/>
                </div>
               <h3>{CareAsstmentBannerDescription}</h3>
            </div>
            <div className={css.toolListcolumn}>
                <div className={css.headerBlock}>
                <PageTitleRev
                    hightlightText={CareAsstmentHighLitedText}
                    title={CareAsstmentTitle}
                    subLine={CareAsstmentDescription}
                />
                </div>
                {/* <h2 className={css.toolPointsHeading}><sapn>Care</sapn> Assessment Tool</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> */}

             <div className={css.toolPoints}>
                    {arr?.map((val,index) =>{
                        return(
                            <div key={index} className={css.CareAsstCheckboxContainer}>
                                <input type="checkbox" defaultChecked name={val} />
                                <label htmlFor={val} className='care-asst-checkbox-value'>{val}</label>
                            </div>
                        )

                        })}
                </div>
                <p>{CareAsstmentDescriptionSection}</p>

                <button className={`${css.viewDetailsButton}  btnBlock btnOutline btnDefault`}>{CareAsstmentButton}</button>
            </div>
        </div>
    )
}
export default CareAsstment
