import React, { useEffect, useState } from 'react';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { displayPrice } from '../../util/configHelpers';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { isBookingProcessAlias } from '../../transactions/transaction';

import {
  AspectRatioWrapper,
  NamedLink,
  ResponsiveImage,
  ReviewRating,
  Reviews,
} from '../../components';

import css from './ListingCard.module.css';
import ButtonComponent from '../../containers/LandingPageCustom/ButtonComponent/ButtonComponent';
import { asyncFetchReviews, fetchReviews } from '../../containers/ListingPage/ListingPage.duck';
import { useDispatch, useSelector, useStore } from 'react-redux';
import AvgReviewRating from '../ReviewRating/AvgReviewRating';
import { addToFavorites, compareDataApi } from '../../containers/SearchPage/SearchPage.duck';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

export const ListingCardComponent = props => {
  const config = useConfiguration();
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
    listingView,
    isshowReview = true,
    onCompare,
    selectedListings,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const [reviews, setReviews] = useState([]);
  const { title = '', price, publicData } = currentListing.attributes;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const setActivePropsMaybe = setActiveListing
    ? {
        onMouseEnter: () => setActiveListing(currentListing.id),
        onMouseLeave: () => setActiveListing(null),
      }
    : null;

  // Get Reviews
  const dispatch = useDispatch();

  const getReviews = async () => {
    const _reviews = await dispatch(asyncFetchReviews(currentListing.id));
    setReviews(_reviews);
  };
  useEffect(() => {
    if (currentListing.id && isshowReview) {
      getReviews();
    }
  }, [currentListing.id]);

  const currentUser = useSelector(state => state.user.currentUser);

  const [isWhisListActive, setIsWishListActive] = useState(false);

  useEffect(() => {
    const favoritesListing = currentUser?.attributes?.profile?.publicData?.favoritesListing || [];
    const isListingAlreadyExist = favoritesListing.includes(id);
    setIsWishListActive(isListingAlreadyExist);

    if (currentUser?.id?.uuid) {
      dispatch(compareDataApi({ listingIds: [id] })).then(res => {
        // console.log('res>>>>', res);
      });
    }
  }, [currentUser]);
  const isOwnListing = currentListing?.author?.id?.uuid === currentUser?.id?.uuid;
  const AddToWishList = async event => {
    event.preventDefault();
    // && !isOwnListing
    if (currentUser?.id?.uuid) {
      await dispatch(addToFavorites({ listingId: id, currentUserId: currentUser?.id?.uuid })).then(
        res => {
          setIsWishListActive(res?.isFavorites);
        }
      );
    }
  };

  const listingType = config.listing.listingTypes?.find(
    dataType => dataType.listingType === currentListing.attributes.publicData.listingType
  );

  const address = currentListing.attributes.publicData?.location?.address;
  return (
    <>
      <NamedLink
        className={classNames(classes, { [css.listCardView]: listingView })}
        name="ListingPage"
        params={{ id, slug }}
      >
        <div className={css.imageWrapper}>
          <div className={css.featuredWishList}>
            <div className={css.tagName}>Featured</div>
            <button
              className={classNames({
                [css.wishListActive]: isWhisListActive,
                [css.wishList]: !isWhisListActive,
              })}
              onClick={AddToWishList}
            >
              <svg
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.0668 17.3416C10.7835 17.4416 10.3168 17.4416 10.0335 17.3416C7.6168 16.5166 2.2168 13.0749 2.2168 7.24159C2.2168 4.66659 4.2918 2.58325 6.85013 2.58325C8.3668 2.58325 9.70846 3.31659 10.5501 4.44992C11.3918 3.31659 12.7418 2.58325 14.2501 2.58325C16.8085 2.58325 18.8835 4.66659 18.8835 7.24159C18.8835 13.0749 13.4835 16.5166 11.0668 17.3416Z"
                  stroke="#0B0F0E"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </div>
          <div className={css.productImageBlock_}>
            <LazyImage
              rootClassName={css.rootForImage}
              alt={title}
              image={firstImage}
              variants={variants}
              sizes={renderSizes}
            />
          </div>
        </div>
        <div className={css.info}>
          <div className={css.reviewCategory}>
            <div className={css.category}>{listingType?.label}</div>
            {isshowReview && (
              <AvgReviewRating
                key={`rating${reviews.length}`}
                ratings={reviews}
                className={css.desktopReviewRating}
                reviewStarClassName={css.reviewRatingStar}
              />
            )}
          </div>
          <div className={css.mainInfo}>
            <div className={css.title}>
              {richText(title, {
                longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                longWordClass: css.longWord,
              })}
            </div>
            {showAuthorInfo ? (
              <div className={css.authorInfo}>
                <FormattedMessage id="ListingCard.author" values={{ authorName: address }} />
              </div>
            ) : null}
          </div>

          <div className={css.buttonWrapper}>
            <ButtonComponent value="View Details" variant="secondary" />
{/*             
            <button className={`${css.viewDetailsButton} compareBtn btnBlock btnOutline btnDefault`}
          >{intl.formatMessage({id:'PartnerPage.HomeCareButtonValue'})}</button>
             */}
            <ButtonComponent
              value={
                selectedListings?.some(item => item.id === listing.id.uuid)
                  ? 'Remove Comparison'
                  : 'Select to Compare'
              }
              customClass="compare"
              // variant="primary"
              variant={
                selectedListings?.some(item => item.id === listing.id.uuid) ? 'primary' : 'secondary'
              }
              className={`${css.viewDetailsButton} compareBtn btnBlock btnOutline btnDefault `}
              onClick={e => {
                e.preventDefault();
                onCompare({ id: id, title: title, address: address });
              }}
              disabled={
                selectedListings?.length >= 3 &&
                !selectedListings?.some(item => item.id === listing.id.uuid)
              }
            />
          </div>
        </div>
      </NamedLink>
    </>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
  isshowReview: bool,
};

export default injectIntl(ListingCardComponent);
