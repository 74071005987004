import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import faqImage from '../../LandingPageCustom/Assets/faqImage.png';
import css from '../../LandingPageCustom/FAQSection/FAQSection.module.css';
import ButtonComponent from '../../LandingPageCustom/ButtonComponent/ButtonComponent';


const FAQSection = () => {
  const intl = useIntl();
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question: intl.formatMessage({ id: 'FAQSection.FAQ1.Question' }),
      answer: intl.formatMessage({ id: 'FAQSection.FAQ1.Answer' }),
    },
    {
      question: intl.formatMessage({ id: 'FAQSection.FAQ2.Question' }),
      answer: intl.formatMessage({ id: 'FAQSection.FAQ2.Answer' }),
    },
    {
      question: intl.formatMessage({ id: 'FAQSection.FAQ3.Question' }),
      answer: intl.formatMessage({ id: 'FAQSection.FAQ3.Answer' }),
    },
    {
      question: intl.formatMessage({ id: 'FAQSection.FAQ4.Question' }),
      answer: intl.formatMessage({ id: 'FAQSection.FAQ4.Answer' }),
    },
  ];

  const toggleAccordion = index => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <section className={classNames(css.faqBlock, css.resourcesFaq)} >
      <div className={css.faqSection}>
        <div className={css.imageContainer}>
          <div className={css.faqSideImage}>
            <img
              src={faqImage}
              alt={intl.formatMessage({ id: 'FAQSection.ImageAlt' })}
              className={css.image}
            />
          </div>
        </div>
        <div className={css.faqAccourding}>
          <div className={css.content}>
            <h2 className={css.heading}>{intl.formatMessage({ id: 'FAQSection.Title' })}</h2>
            <p className={css.description}>
              {intl.formatMessage({ id: 'FAQSection.Description' })}
            </p>
            <div className={css.accordionFaq}>
              {faqs.map((faq, index) => (
                <div
                  key={index}
                  className={classNames(css.accordionItem, {
                    [css.active]: activeIndex === index,
                  })}
                >
                  <div className={css.accordionHeader} onClick={() => toggleAccordion(index)}>
                    <span className={css.faqQuestion}>{faq.question}</span>
                    <span className={css.icon}>{activeIndex === index ? '-' : '+'}</span>
                  </div>
                  {activeIndex === index && (
                    <div className={css.accordionBody}>
                      <p>{faq.answer}</p>
                    </div>
                  )}
                </div>
              ))}
            </div>
            <ButtonComponent
              value={intl.formatMessage({ id: 'FAQSection.ButtonValue' })}
              variant="secondary"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQSection;
