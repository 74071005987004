import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import faqImage from '../../LandingPageCustom/Assets/faqImage.png';
import css from '../../LandingPageCustom/FAQSection/FAQSection.module.css';
import ButtonComponent from '../../LandingPageCustom/ButtonComponent/ButtonComponent';


const PartnerWithUs = () => {
  const intl = useIntl();
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question: intl.formatMessage({ id: 'PartnerPage.PartnerWithUs1.Question' }),
      answer: intl.formatMessage({ id: 'PartnerPage.PartnerWithUs1.Answer' }),
    },
    {
      question: intl.formatMessage({ id: 'PartnerPage.PartnerWithUs2.Question' }),
      answer: intl.formatMessage({ id: 'PartnerPage.PartnerWithUs2.Answer' }),
    },
    {
      question: intl.formatMessage({ id: 'PartnerPage.PartnerWithUs3.Question' }),
      answer: intl.formatMessage({ id: 'PartnerPage.PartnerWithUs3.Answer' }),
    },
    {
      question: intl.formatMessage({ id: 'PartnerPage.PartnerWithUs4.Question' }),
      answer: intl.formatMessage({ id: 'PartnerPage.PartnerWithUs4.Answer' }),
    },
  ];
  const arr = [
    intl.formatMessage({id:'PartnerPage.PartnerWithUsDataSection'}),
    intl.formatMessage({id:'PartnerPage.PartnerWithUsDataSection1'}),
    intl.formatMessage({id:'PartnerPage.PartnerWithUsDataSection2'}),
    intl.formatMessage({id:'PartnerPage.PartnerWithUsDataSection3'}),
    intl.formatMessage({id:'PartnerPage.PartnerWithUsDataSection4'}),
    intl.formatMessage({id:'PartnerPage.PartnerWithUsDataSection5'}),
  ]

  const toggleAccordion = index => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <section className={classNames(css.faqBlock, css.resourcesFaq)} >
      <div className={classNames(css.faqSection, css.partnerWorkPointsBlock)}>

        <div className={css.faqAccourding}>
          <div className={css.content}>
            <h2 className={css.heading}>{intl.formatMessage({ id: 'PartnerPage.PartnerWithUsTitle' })}</h2>
            <div className={css.accordionFaq}>
              {faqs.map((faq, index) => (
                <div
                  key={index}
                  className={classNames(css.accordionItem, {
                    [css.active]: activeIndex === index,
                  })}
                >
                  <div className={css.accordionHeader} onClick={() => toggleAccordion(index)}>
                    <span className={css.faqQuestion}>{faq.question}</span>
                    <span className={css.icon}>{activeIndex === index ? '-' : '+'}</span>
                  </div>
                  {activeIndex === index && (
                    <div className={css.accordionBody}>
                      <p>{faq.answer}</p>
                    </div>
                  )}
                </div>
              ))}
            </div>
            {/* <ButtonComponent
              value={intl.formatMessage({ id: 'PartnerPage.PartnerWithUsButtonValue' })}
              variant="secondary"
            /> */}
          </div>
        </div>
        <div className={css.workWithPoints}>
            <h2>{intl.formatMessage({ id: 'PartnerPage.PartnerWithUsHeader' })}</h2>
            <div className={css.pointsCards}>
                {arr?.map((val, index) => {
                return (
                <div key={index} className={css.checkbocContainer}>
                    <input type="checkbox" defaultChecked name={val} />
                    <label htmlFor={val} className={css.careAsstCheckboxValue}>
                       {val}
                    </label>
                </div>
                );
            })}
            </div>
        </div>
      </div>
    </section>
  );
};

export default PartnerWithUs;
