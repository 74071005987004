import React from 'react';

const IndependentLivingIcon = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22">
      <path
        opacity="0.4"
        d="M11 0C10.0433 0 9.20847 0.335711 8.34744 0.868538C7.52133 1.37975 6.58333 2.13018 5.42731 3.05503L3.20512 4.83268C1.89776 5.8774 0.988592 6.60392 0.493484 7.63406C-0.00162417 8.66419 -0.000990679 9.82798 -7.98013e-05 11.5015L-8.84727e-07 12.9465C-2.50842e-05 14.774 -4.51058e-05 16.2727 0.154889 17.4575C0.314222 18.6759 0.654693 19.7258 1.46447 20.5355C2.26702 21.3381 3.27752 21.6833 4.47767 21.8446C5.63363 22 7.1034 22 8.92686 22H13.0731C14.8966 22 16.3664 22 17.5223 21.8446C18.7225 21.6833 19.733 21.3381 20.5355 20.5355C21.3453 19.7258 21.6858 18.6759 21.8451 17.4575C22 16.2727 22 14.774 22 12.9466L22.0001 11.5015C22.001 9.82798 22.0016 8.66419 21.5065 7.63406C21.0114 6.60393 20.1023 5.87741 18.7949 4.8327L16.5727 3.05503C15.4167 2.13019 14.4787 1.37975 13.6526 0.868538C12.7915 0.335711 11.9567 0 11 0Z"
        fill="currentColor"
      />
      <path
        d="M7.77635 9.88828C8.63865 8.94506 9.95321 8.75301 10.9997 9.31212C12.0462 8.75301 13.3607 8.94506 14.223 9.88828C15.2586 11.021 15.2586 12.8235 14.223 13.9562C14.2002 13.9812 14.1761 14.005 14.1509 14.0275L11.6656 16.246C11.2862 16.5847 10.7131 16.5847 10.3338 16.246L7.84848 14.0275C7.82324 14.005 7.79917 13.9812 7.77635 13.9562C6.7408 12.8235 6.7408 11.021 7.77635 9.88828Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IndependentLivingIcon;
