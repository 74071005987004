import React from 'react';

const IconLl = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.4696 0.000139831H1.52957C1.33915 -0.00250479 1.15007 0.0323873 0.973136 0.102824C0.796201 0.17326 0.634874 0.27786 0.498368 0.410652C0.361862 0.543443 0.252852 0.701824 0.177562 0.876749C0.102273 1.05167 0.0621787 1.23972 0.0595703 1.43014V18.5701C0.0621787 18.7606 0.102273 18.9486 0.177562 19.1235C0.252852 19.2985 0.361862 19.4568 0.498368 19.5896C0.634874 19.7224 0.796201 19.827 0.973136 19.8975C1.15007 19.9679 1.33915 20.0028 1.52957 20.0001H18.4696C18.66 20.0028 18.8491 19.9679 19.026 19.8975C19.2029 19.827 19.3643 19.7224 19.5008 19.5896C19.6373 19.4568 19.7463 19.2985 19.8216 19.1235C19.8969 18.9486 19.937 18.7606 19.9396 18.5701V1.43014C19.937 1.23972 19.8969 1.05167 19.8216 0.876749C19.7463 0.701824 19.6373 0.543443 19.5008 0.410652C19.3643 0.27786 19.2029 0.17326 19.026 0.102824C18.8491 0.0323873 18.66 -0.00250479 18.4696 0.000139831ZM6.08957 16.7401H3.08957V7.74014H6.08957V16.7401ZM4.58957 6.48014C4.17583 6.48014 3.77904 6.31578 3.48648 6.02323C3.19393 5.73067 3.02957 5.33388 3.02957 4.92014C3.02957 4.5064 3.19393 4.10961 3.48648 3.81705C3.77904 3.5245 4.17583 3.36014 4.58957 3.36014C4.80927 3.33522 5.03175 3.35699 5.24245 3.42402C5.45314 3.49105 5.64731 3.60183 5.81223 3.7491C5.97715 3.89637 6.1091 4.07682 6.19944 4.27862C6.28979 4.48043 6.33649 4.69904 6.33649 4.92014C6.33649 5.14124 6.28979 5.35985 6.19944 5.56166C6.1091 5.76346 5.97715 5.94391 5.81223 6.09118C5.64731 6.23845 5.45314 6.34923 5.24245 6.41626C5.03175 6.48329 4.80927 6.50505 4.58957 6.48014ZM16.9096 16.7401H13.9096V11.9101C13.9096 10.7001 13.4796 9.91014 12.3896 9.91014C12.0522 9.91261 11.7238 10.0184 11.4484 10.2133C11.1731 10.4082 10.9641 10.6828 10.8496 11.0001C10.7713 11.2352 10.7374 11.4827 10.7496 11.7301V16.7301H7.74957C7.74957 16.7301 7.74957 8.55014 7.74957 7.73014H10.7496V9.00014C11.0221 8.52725 11.4185 8.13766 11.896 7.87334C12.3735 7.60902 12.9141 7.47999 13.4596 7.50014C15.4596 7.50014 16.9096 8.79014 16.9096 11.5601V16.7401Z"
        fill="#262BBA"
      />
    </svg>
  );
};

export default IconLl;
