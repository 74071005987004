import React from 'react'

const IconCalender = () => {
  return (
    <>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path fill-rule="evenodd" clip-rule="evenodd" d="M6 4V2H8V4H16V2H18V4L18.0658 4C18.9523 3.99995 19.7161 3.9999 20.3278 4.08214C20.9833 4.17027 21.6117 4.36902 22.1213 4.87868C22.631 5.38834 22.8297 6.01669 22.9179 6.67221C23.0001 7.28387 23.0001 8.0477 23 8.93419V17.0658C23.0001 17.9523 23.0001 18.7161 22.9179 19.3278C22.8297 19.9833 22.631 20.6117 22.1213 21.1213C21.6117 21.631 20.9833 21.8297 20.3278 21.9179C19.7161 22.0001 18.9523 22.0001 18.0658 22H5.9342C5.0477 22.0001 4.28388 22.0001 3.67221 21.9179C3.0167 21.8297 2.38835 21.631 1.87868 21.1213C1.36902 20.6117 1.17028 19.9833 1.08215 19.3278C0.99991 18.7161 0.999953 17.9523 1 17.0658V8.93417C0.999953 8.04768 0.99991 7.28387 1.08215 6.67221C1.17028 6.01669 1.36902 5.38834 1.87868 4.87868C2.38835 4.36902 3.0167 4.17027 3.67221 4.08214C4.28387 3.9999 5.04769 3.99995 5.93417 4C5.95604 4 5.97799 4 6 4ZM3 9V10H21V9C21 8.02892 20.9979 7.40121 20.9357 6.9387C20.8774 6.50496 20.7832 6.36902 20.7071 6.29289C20.631 6.21677 20.495 6.12262 20.0613 6.06431C19.5988 6.00212 18.9711 6 18 6H6C5.02893 6 4.40122 6.00212 3.93871 6.06431C3.50497 6.12262 3.36902 6.21677 3.2929 6.29289C3.21677 6.36902 3.12263 6.50496 3.06431 6.9387C3.00213 7.40121 3 8.02892 3 9ZM21 12H3V17C3 17.9711 3.00213 18.5988 3.06431 19.0613C3.12263 19.495 3.21677 19.631 3.2929 19.7071C3.36902 19.7832 3.50497 19.8774 3.93871 19.9357C4.40122 19.9979 5.02893 20 6 20H18C18.9711 20 19.5988 19.9979 20.0613 19.9357C20.495 19.8774 20.631 19.7832 20.7071 19.7071C20.7832 19.631 20.8774 19.495 20.9357 19.0613C20.9979 18.5988 21 17.9711 21 17V12ZM19 18H17V16H19V18Z" fill="#262BBA"/>
</svg>
    </>
  )
}

export default IconCalender
