import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import faqImage from '../../LandingPageCustom/Assets/faqImage.png';
import css from '../../LandingPageCustom/FAQSection/FAQSection.module.css';
import ButtonComponent from '../../LandingPageCustom/ButtonComponent/ButtonComponent';

const Partnership = () => {
  const intl = useIntl();
  const [activeIndex, setActiveIndex] = useState(null);
  const chk = [];
  for (let i = 0; i <= 3; i++) {
    chk.push(
      <div key={i} className={css.checkbocContainer}>
        <input type="checkbox" defaultChecked id={i} />
      </div>
    );
  }
  const faqs = [
    {
      question: intl.formatMessage({ id: 'PartnerPage.Partnership1.Question' }),
      answer: intl.formatMessage({ id: 'PartnerPage.Partnership1.Answer' }),
    },
    {
      question: intl.formatMessage({ id: 'PartnerPage.Partnership2.Question' }),
      answer: intl.formatMessage({ id: 'PartnerPage.Partnership2.Answer' }),
    },
    {
      question: intl.formatMessage({ id: 'PartnerPage.Partnership3.Question' }),
      answer: intl.formatMessage({ id: 'PartnerPage.Partnership3.Answer' }),
    },
    {
      question: intl.formatMessage({ id: 'PartnerPage.Partnership4.Question' }),
      answer: intl.formatMessage({ id: 'PartnerPage.Partnership4.Answer' }),
    },
  ];

  const toggleAccordion = index => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <section className={css.faqBlock}>
      <div className={classNames(css.faqSection, css.partnerWorkPointsBlock)}>
        <div className={css.workWithPoints}>
          <h2>{intl.formatMessage({id:'PartnerPage.PartnershipTitle'})}</h2>

          <div className={classNames(css.pointsCards, css.checkBoxGrid)}>{chk}</div>
        </div>
        <div className={css.faqAccourding}>
          <div className={css.content}>
            <h2 className={css.heading}>{intl.formatMessage({id:'PartnerPage.PartnershipHeader'})}</h2>
            <div className={css.accordionFaq}>
              {faqs.map((faq, index) => (
                <div
                  key={index}
                  className={classNames(css.accordionItem, {
                    [css.active]: activeIndex === index,
                  })}
                >
                  <div className={css.accordionHeader} onClick={() => toggleAccordion(index)}>
                    <span className={css.faqQuestion}>{faq.question}</span>
                    <span className={css.icon}>{activeIndex === index ? '-' : '+'}</span>
                  </div>
                  {activeIndex === index && (
                    <div className={css.accordionBody}>
                      <p>{faq.answer}</p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Partnership;
