import React, { useState } from 'react';
import { Form as FinalForm, Field } from 'react-final-form'
import { compose } from 'redux';
import * as validators from '../../../util/validators';
import ButtonComponent from '../../LandingPageCustom/ButtonComponent/ButtonComponent';
import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import { PageTitle } from '../../OtherPagesComponents/PageHeadingTitle/PageTitle';
import css from './QuestionSection.module.css'
import classNames from 'classnames';
const QuestionSectionComponent = props => {
const [ isResponse, setIsResponse ] = useState(true)
const { response, companySelectRef,intl } = props

const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = intl.formatMessage({id:'PartnerPage.QuestionFormNameValidateRequiredMsg'});
  }
  if (!values.email) {
    errors.email = intl.formatMessage({id:'PartnerPage.QuestionFormEmailValidateRequiredMsg'});
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = intl.formatMessage({id:'PartnerPage.QuestionFormEmailValidateFormatErrMsg'});
  }
  // if (!values.phone) {
  //   errors.phone =  intl.formatMessage({id:'PartnerPage.QuestionFormPhoneValidateRequiredMsg'});;
  // }
  if (!values.company) {
    errors.company = "Company is required";
  }
  // if (!values.question) {
  //   errors.question = "Question is required";
  // }

  // if (!values.terms_condition) {
  //   errors.terms_condition = "You must agree to the terms";
  // }

  return errors; // Return errors object
};

if(response && response?.message === "Mail send successfully" && isResponse){
  setTimeout(() => {
    setIsResponse(false)
  },5000 );
}
if(props?.response && props?.response?.error && isResponse){
  setTimeout(() => {
    setIsResponse(false)
  },5000 );
}


return(
    <>
    {(response && response?.message === "Mail send successfully" && isResponse) ? (
        <>
        <div>
          <p><FormattedMessage id='PartnerPage.QuestionFormResponseSuccessMsg' /></p>
          {/* <p>{intl.formatMessage({id:'PartnerPage.QuestionFormResponseSuccessMsg'})}</p> */}
        </div><br/>
        </>
    ):''}

  {(props?.response && props?.response?.error && isResponse) ? (
        <>
        <div>
          {/* <p><FormattedMessage id='PartnerPage.QuestionFormResponseErrorMsg' /></p> */}
          <p>{intl.formatMessage({id:'PartnerPage.QuestionFormResponseErrorMsg'})}</p>

        </div><br/>
        </>
    ):''}

  <section className={css.questionFormBlock}>
          <PageTitle
            title={intl.formatMessage({id:"PartnerPage.QuestionTitle"})}
            subLine={intl.formatMessage({id:"PartnerPage.QuestionDesc"})}
            className={css.midWidth}
        />

    <FinalForm
        {...props}
        validate={validate}

      initialValues={{ name: '', email: '', phone: '', company: '', question: '', terms_condition:false}}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit} className={css.questionForm} >
          <div className={css.fieldInput}>
            <label>{intl.formatMessage({id:"PartnerPage.QuestionFormNameLabel"})}</label>
            <Field
              name="name"
              component="input"
              type="text"
              placeholder={intl.formatMessage({id:"PartnerPage.QuestionFormNamePlaceholder"})}

            />
             <Field name="name" >
                {({ meta }) => (
                  meta.touched && meta.error && <span style={{ color: 'red' }}>{meta.error}</span>
                )}
              </Field>
          </div>
          <div className={css.fieldInput}>
            <label>{intl.formatMessage({id:"PartnerPage.QuestionFormEmailLabel"})}</label>
            <Field
              name="email"
              component="input"
              type="text"
              placeholder= {intl.formatMessage({id:"PartnerPage.QuestionFormEmailPlaceholder"})}
            />
            <Field name="email">
                {({ meta }) => (
                  meta.touched && meta.error && <span style={{ color: 'red' }}>{meta.error}</span>
                )}
              </Field>
          </div>

          <div className={css.fieldInput}>
            <label>{intl.formatMessage({id:"PartnerPage.QuestionFormPhoneLabel"})}</label>
            <Field
              name="phone"
              component="input"
              type="text"
              placeholder={intl.formatMessage({id:'PartnerPage.QuestionFormPhonePlaceholder'})}
            />
            <Field name="phone">
                {({ meta }) => (
                  meta.touched && meta.error && <span style={{ color: 'red' }}>{meta.error}</span>
                )}
              </Field>
          </div>

          <div className={css.fieldInput}>
            <label>{intl.formatMessage({id:"PartnerPage.QuestionFormCompanyLabel"})}</label>
            <Field name="company">
              {({ input, meta }) => (
                  <>
                      <select id="companySelect" ref={companySelectRef} onChange={form.mutators.handleChange} >
                          <option value="">{intl.formatMessage({id:'PartnerPage.QuestionFormCompanyDropdownPlaceholder'})}</option>
                          <option value={intl.formatMessage({id:'PartnerPage.QuestionFormCompanyDropdownValue'})}>{intl.formatMessage({id:'PartnerPage.QuestionFormCompanyDropdownValue'})}</option>
                          <option value={intl.formatMessage({id:'PartnerPage.QuestionFormCompanyDropdownValue1'})}>{intl.formatMessage({id:'PartnerPage.QuestionFormCompanyDropdownValue1'})}</option>
                          <option value={intl.formatMessage({id:'PartnerPage.QuestionFormCompanyDropdownValue2'})}>{intl.formatMessage({id:'PartnerPage.QuestionFormCompanyDropdownValue2'})}</option>
                      </select>

                      {meta.touched && meta.error && <span style={{ color: 'red' }}>{meta.error}</span>}
                  </>
                )}
            </Field>
          </div>

          <div className={classNames(css.fieldInput , css.textAreaField)}>
            <label>{intl.formatMessage({id:"PartnerPage.QuestionFormQuestionLabel"})}</label>
            <Field name="question" component="textarea" placeholder={intl.formatMessage({id:'PartnerPage.QuestionFormQuestionPlaceholder'})} rows="4" cols="50" />
              <Field name="question">
                {({ meta }) => (
                  meta.touched && meta.error && <span style={{ color: 'red' }}>{meta.error}</span>
                )}
              </Field>
            </div>
            <div className={css.checkBoxTerm}>
              <Field
                name="terms_condition"
                component="input"
                type="checkbox"
                onClick={(e)=>{e.target.value=true}}
                placeholder="Choose company size"
              />

              <label>{intl.formatMessage({id:"PartnerPage.QuestionFormTermConditionLabel"})}</label>
            </div>

            <div className={css.button}>
               <ButtonComponent
                value={intl.formatMessage({id:'PartnerPage.QuestionFormButtonValue'})}
                variant='secondary'
                type="submit" />

          </div>
        </form>
      )}
    />
    </section>
    </>
)

}

QuestionSectionComponent.defaultProps = {
  resetPasswordError: null,
};

QuestionSectionComponent.propTypes = {
  // scrollingDisabled: bool.isRequired,
  intl: intlShape.isRequired,
};

const QuestionSection = compose(
  injectIntl
)(QuestionSectionComponent);

QuestionSection.displayName = 'QuestionSection';
export default QuestionSection;

