import React from 'react';

const MemoryIcon = () => {
  return (
    <svg width="22" height="18" viewBox="0 0 22 18">
      <path
        opacity="0.4"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13 0C11.7279 0 10.573 0.500849 9.69929 1.31001C9.38734 1.59892 9.15586 1.8133 8.99605 1.96007C8.91588 2.0337 8.8567 2.08759 8.81574 2.12426L8.80967 2.12969L8.76759 2.1303C8.68591 2.13128 8.56707 2.13196 8.40795 2.13242C8.09038 2.13333 7.62441 2.13333 7 2.13333C4.98878 2.13333 3.4002 3.65934 3.06526 5.57853C1.25324 6.21666 0 8.01691 0 10.0667C0 12.619 1.9549 14.8 4.5 14.8H11.0296C11.4074 14.8 11.6057 14.8015 11.7453 14.8177L11.7513 14.8184L11.7537 14.8239C11.8108 14.9524 11.8714 15.1412 11.9841 15.5017L12.1269 15.9587L12.1386 15.9961L12.1386 15.9962C12.2243 16.2705 12.31 16.5449 12.4077 16.7711C12.5187 17.0281 12.679 17.3081 12.9595 17.5404C13.0188 17.5895 13.0809 17.6351 13.1454 17.677C13.4508 17.8755 13.7659 17.945 14.0443 17.9743C14.2893 18.0001 14.5768 18.0001 14.8643 18L14.9035 18H15.8237L15.8874 18.0001C16.0996 18.0006 16.3526 18.0013 16.5718 17.9528C17.5506 17.7363 18.2178 16.8286 18.1326 15.8299C18.1135 15.6062 18.0374 15.3649 17.9736 15.1624L17.9545 15.1017L17.8375 14.7273C20.241 14.3045 22 12.0913 22 9.53333C22 7.56535 20.9712 5.82339 19.4104 4.91779C18.9121 4.62865 18.5509 4.41906 18.3006 4.26766C18.1973 4.20514 18.121 4.15743 18.0663 4.12161C18.0401 4.07373 18.0061 4.00867 17.9625 3.922C17.8464 3.69142 17.6881 3.36051 17.4678 2.89972C16.6583 1.20632 14.981 0 13 0ZM3.00001 6.33875L3 6.33333H3.95562H4L3.95566 6.33357L3.00001 6.33875Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14 9C14 7.89543 13.1046 7 12 7V5C14.2091 5 16 6.79086 16 9H14Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default MemoryIcon;
