import React from 'react';
import financialAsst from '../Assets/financialAsst.png';
import css from '../CareAsstment/CareAsstment.module.css';
import { PageTitleRev } from '../../OtherPagesComponents/PageHeadingTitle/PageTitle';
import { useIntl } from 'react-intl';
const FinancialAsstment = () => {
  const intl = useIntl()
  const FinancialAsstmentTitle = intl.formatMessage({id:'ResourcePage.FinancialAsstmentTitle'})
    const FinancialAsstmentHighLitedText = intl.formatMessage({id:'ResourcePage.FinancialAsstmentHighLitedText'})
    const FinancialAsstmentDescription = intl.formatMessage({id:'ResourcePage.FinancialAsstmentDescription'})
    const FinancialAsstmentBannerDescription = intl.formatMessage({id:'ResourcePage.CareAsstmentBannerDescription'})
    const FinancialAsstmentDataSection = intl.formatMessage({id:'ResourcePage.CareAsstmentDataSection'})
    const FinancialAsstmentDataSection1 = intl.formatMessage({id:'ResourcePage.FinancialAsstmentDataSection1'})
    const FinancialAsstmentDataSection2 = intl.formatMessage({id:'ResourcePage.FinancialAsstmentDataSection2'})
    const FinancialAsstmentDataSection3 = intl.formatMessage({id:'ResourcePage.FinancialAsstmentDataSection3'})
    const FinancialAsstmentDataSection4 = intl.formatMessage({id:'ResourcePage.FinancialAsstmentDataSection4'})
    const FinancialAsstmentDataSection5 = intl.formatMessage({id:'ResourcePage.FinancialAsstmentDataSection5'})
    const FinancialAsstmentDataSection6 = intl.formatMessage({id:'ResourcePage.FinancialAsstmentDataSection6'})
    const FinancialAsstmentDataSection7 = intl.formatMessage({id:'ResourcePage.FinancialAsstmentDataSection7'})
    const FinancialAsstmentDescriptionSection = intl.formatMessage({id:'ResourcePage.FinancialAsstmentDescriptionSection'})
    const FinancialAsstmentButton = intl.formatMessage({id:'ResourcePage.FinancialAsstmentButton'})

  const arr = [
    FinancialAsstmentDataSection,
    FinancialAsstmentDataSection1,
    FinancialAsstmentDataSection2,
    FinancialAsstmentDataSection3,
    FinancialAsstmentDataSection4,
    FinancialAsstmentDataSection5,
    FinancialAsstmentDataSection6,
    FinancialAsstmentDataSection7,
  ];
  return (
    <div className={css.resourcesToolsBlock}>
      <div className={css.imageColumn}>
        <div className={css.imageBlock}>
          <img src={financialAsst} alt="test" height={300} width={100} />
        </div>
        <h3>{FinancialAsstmentBannerDescription}</h3>
      </div>
      <div className={css.toolListcolumn}>
        <div className={css.headerBlock}>
          <PageTitleRev
            hightlightText={FinancialAsstmentHighLitedText}
            title={FinancialAsstmentTitle}
            subLine={FinancialAsstmentDescription}
          />
        </div>
        {/* <h2 className={css.toolPointsHeading}><sapn>Care</sapn> Assessment Tool</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> */}

        <div className={css.toolPoints}>
          {arr?.map((val, index) => {
            return (
              <div key={index} className={css.CareAsstCheckboxContainer}>
                <input type="checkbox" defaultChecked name={val} />
                <label htmlFor={val} className="care-asst-checkbox-value">
                  {val}
                </label>
              </div>
            );
          })}
        </div>
        <p>
         {FinancialAsstmentDescriptionSection}
        </p>

        <button className={`${css.viewDetailsButton}  btnBlock btnOutline btnDefault`}>
          {FinancialAsstmentButton}
        </button>
      </div>
    </div>
  );
};
export default FinancialAsstment;
