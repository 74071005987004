import React from 'react';
import { LayoutSingleColumn, Page } from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';
import CareAsstment from './CareAsstment/CareAsstment';
import LifeStyleAsstment from './LifeStyleAsstment/LifeStyleAsstment';
import FinancialAsstment from './FinancialAsstment/FinancialAsstment';
import FrequentAskQues from './FrequentlyAskQus/FrequentlyAskQus';
import Banner from './Banner/Banner';
import css from './Resiyrce.module.css'
import FAQSection from '../LandingPageCustom/FAQSection/FAQSection';
const Resources = () => {
  return (
    <Page title={''} scrollingDisabled={false}>

      <div className={'resources-header'}>
        <LayoutSingleColumn topbar={<TopbarContainer />} footer={<FooterContainer />}>
          <div className={css.resourcesPage}>          
            <Banner />
            <CareAsstment />
            <LifeStyleAsstment />
            <FinancialAsstment />
            <FrequentAskQues />
           {/* <FAQSection/> */}
        </div>
        </LayoutSingleColumn>
      </div>
    </Page>
  );
};
export default Resources;
