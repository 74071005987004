import React, { useEffect, useState } from 'react';
import css from './CustomeFilterStyle.module.css';
import IconArrowUp from '../../../components/IconArrowUp/IconArrowUp';
import IconSingleStar from '../../../components/IconSingleStar/IconSingleStar';
import CustomCheckBox from './CustomCheckBox';
import IconDownArrow from '../../../components/IconDownArrow/IconDownArrow';
import { userLocation } from '../../../util/maps';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { createResourceLocatorString } from '../../../util/routes';
import { useRouteConfiguration } from '../../../context/routeConfigurationContext';
import { performSearch } from './LocationHelper';
const radiusInKm = {
  upTo10: 16.09,
  '10to20': [16.09, 32.19],
  moreThan20: 32.19,
};

const SearchRadius = props => {
  const { urlQueryParams } = props;
  const [currentLocation, setCurrentLocation] = useState();
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);
  const searchRadius = searchParams.get('searchRadii');
  const [selected, setSelected] = useState(searchRadius);

  userLocation().then(latlng => {
    setCurrentLocation(latlng);
  });

  const routeConfiguration = useRouteConfiguration();

  const bestFilter = [
    {
      value: 'upTo10',
      filterText: 'Up to 10 miles',
    },
    {
      value: '10to20',
      filterText: '10 to 20 miles',
    },
    {
      value: 'moreThan20',
      filterText: 'more then 20 miles',
    },
  ];

  useEffect(()=>{
    if(selected != searchParams.get('searchRadii')){
      setSelected(searchParams.get('searchRadii'))
    }
  },[searchParams.get('searchRadii')])

  useEffect(() => {
    if (currentLocation && selected) {
      const search = {
        ...urlQueryParams,
        ...performSearch(selected, currentLocation, radiusInKm[selected], 'searchRadii'),
      };
      delete search.searchClose
      history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, search));
    }
  }, [selected]);
  const [isOpen, setIsOpen] = useState(true);
  const [arrow, setArrow] = useState(true);

  const openFilterHandler = () => {
    setIsOpen(!isOpen);
    setArrow(!arrow);
  };

  const handleChange = (e)=>{
    const isChecked = e.target.checked;
    if (isChecked) {
      setSelected(e.target.value);
    } else {
      setSelected()
      const search = JSON.parse(JSON.stringify(urlQueryParams));
      delete search.origin;
      delete search.bounds;
      delete search.searchRadii
      history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, search));
    }
  }

  return (
    <>
      <div className={css.filterHeader}>
        <button type="button" className={css.labelButton} onClick={openFilterHandler}>
          <span className={css.labelButtonContent}>
            <span className={css.labelWrapper}>
              <span className={css.label}>Search Radius</span>
            </span>
            <span className={css.openSign}>{arrow ? <IconArrowUp /> : <IconDownArrow />}</span>
          </span>
        </button>
      </div>

      {isOpen ? (
        <div className={css.filterLists}>
          <ul>
            {bestFilter.map(items => (
              <li key={items.value}>
                <span className={css.customeCheckBox}>
                  <CustomCheckBox
                    checked={selected == items?.value}
                    value={items.value}
                    onChange={handleChange}
                  />
                </span>
                <span className={css.textRoot}>{items.filterText}</span>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        ' '
      )}
    </>
  );
};

export default SearchRadius;
