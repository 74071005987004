import React, { useEffect, useState, useCallback } from 'react';
import css from './CustomeFilterStyle.module.css';
import IconArrowUp from '../../../components/IconArrowUp/IconArrowUp';
import CustomCheckBox from './CustomCheckBox';
import IconDownArrow from '../../../components/IconDownArrow/IconDownArrow';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { createResourceLocatorString } from '../../../util/routes';
import { useRouteConfiguration } from '../../../context/routeConfigurationContext';

const CategoryFilter = ({ urlQueryParams, options }) => {
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchRadius = searchParams.get('pub_categoryLevel1');
  const [selected, setSelected] = useState(searchRadius);
  const routeConfiguration = useRouteConfiguration();

  // Only update the state when the selected category changes
  useEffect(() => {
    const currentCategory = searchParams.get('pub_categoryLevel1');
    if (currentCategory !== selected) {
      setSelected(currentCategory);
    }
  }, [searchParams, selected]);

  const updateSearchParams = useCallback(
    (category) => {
      const search = { ...urlQueryParams };
      if (category) {
        search.pub_categoryLevel1 = category;
      } else {
        delete search.pub_categoryLevel1;
      }
      // Update the URL only if the category has changed
      history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, search));
    },
    [history, routeConfiguration, urlQueryParams]
  );

  const handleChange = (e) => {
    const isChecked = e.target.checked;
    const category = isChecked ? e.target.value : null;
    setSelected(category);
    updateSearchParams(category);
  };

  const [isOpen, setIsOpen] = useState(true);
  const [arrow, setArrow] = useState(true);

  const openFilterHandler = () => {
    setIsOpen((prev) => !prev);
    setArrow((prev) => !prev);
  };

  return (
    <>
      <div className={css.filterHeader}>
        <button type="button" className={css.labelButton} onClick={openFilterHandler}>
          <span className={css.labelButtonContent}>
            <span className={css.labelWrapper}>
              <span className={css.label}>Category</span>
            </span>
            <span className={css.openSign}>{arrow ? <IconArrowUp /> : <IconDownArrow />}</span>
          </span>
        </button>
      </div>

      {isOpen && (
        <div className={css.filterLists}>
          <ul>
            {options.map((item) => (
              <li key={item.id}>
                <span className={css.customeCheckBox}>
                  <CustomCheckBox
                    checked={selected === item.option}
                    value={item.option}
                    onChange={handleChange}
                  />
                </span>
                <span className={css.textRoot}>{item.label}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default CategoryFilter;
