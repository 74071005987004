
import React from 'react';
import image from '../Assets/image .png'
import {PageTitle} from '../../OtherPagesComponents/PageHeadingTitle/PageTitle';
import css  from './Banner.module.css';
import { useIntl } from 'react-intl';
const Banner = () => {
  const intl = useIntl()
  const RescourceTitle = intl.formatMessage({id:'ResourcePage.Title'})
  const RescourceHighLitedText = intl.formatMessage({id:'ResourcePage.HighLitedText'})
  const RescourceDescription = intl.formatMessage({id:'ResourcePage.Description'})
  const RescourceBannerDescription = intl.formatMessage({id:'ResourcePage.BannerDescription'})
return(
    <div className={css.resourcesBannerBlock}>
        <div className={css.minWidth}>
       <PageTitle
            title={RescourceTitle}
            hightlightText={RescourceHighLitedText}
            subLine={RescourceDescription}
            className={css.midWidth}
          />
        </div>
        <div className={css.resourcesBanner}>
         <div className={css.bannerImageBlock}>
              <img src={image} alt={'resources'} className='res-img'/>
            </div>
                 <p>{RescourceBannerDescription}</p>
        </div>


    </div>
)
}
export default Banner
