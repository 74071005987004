import React from 'react';
import blog from '../Assets/recent-image.png';
import blog1 from '../Assets/recent-image1.png';
import blog2 from '../Assets/recent-image2.png';
import vector from '../Assets/Vector.svg';
import vector1 from '../Assets/Vector1.svg';
import { useIntl } from 'react-intl';
// import css from '../../SearchPage/SearchResultsPanel/SearchResultsPanel.module.css';
import { PaginationLinks } from '../../../components';
import css from './Recent.module.css';

const RecentCard = ({category, date, title, description, image }) => {
  const intl = useIntl();
  return (
    <div className={css.recentArticleCard}>
      <div className={css.recentArticleImage}>
        <img src={image} alt={title} height={300} width={100}/>
      </div>
      <div className={css.recentArticleContent}>
        <div className={css.recentArticleMeta}>
          <div className={css.dateCat}>
            <img src={vector} alt={title} height={100} width={150}/>
           <p>{date} </p>
          </div>
          <div className={css.dateCat}>
          <img src={vector1} alt={title} height={100} width={150}/>
            <p>{category}</p>
          </div>

        </div>
        <div className={css.articleDescription}>
            <p className={css.recentArticleTitle}>{title}</p>
            <p className={css.recentArticleDescription}>{description}</p>
            </div>
        <button className={css.ReadMore} >
             {intl.formatMessage({ id: 'BlogPage.ViewMoreButton' })}
             <span className='iconArrowRight'></span>
        </button>
      </div>
    </div>
  );
};

const RecentArticleSection = () => {
  const intl = useIntl();
  const Title = intl.formatMessage({ id: 'BlogPage.RecentTitle' });
  const Description = intl.formatMessage({ id: 'BlogPage.RecentDesc' });

  const RecentDataCategorySection = intl.formatMessage({ id: 'BlogPage.RecentDataCategorySection' });
  const RecentDataTitleSection = intl.formatMessage({ id: 'BlogPage.RecentDataTitleSection' });
  const RecentDataDescriptionSection = intl.formatMessage({ id: 'BlogPage.RecentDataDescriptionSection' });
  const RecentDataCategorySection1 = intl.formatMessage({ id: 'BlogPage.RecentDataCategorySection1' });
  const RecentDataTitleSection1 = intl.formatMessage({ id: 'BlogPage.RecentDataTitleSection1' });
  const RecentDataDescriptionSection1 = intl.formatMessage({ id: 'BlogPage.RecentDataDescriptionSection1' });
  const RecentDataCategorySection2 = intl.formatMessage({ id: 'BlogPage.RecentDataCategorySection2' });
  const RecentDataTitleSection2 = intl.formatMessage({ id: 'BlogPage.RecentDataTitleSection2' });
  const RecentDataDescriptionSection2 = intl.formatMessage({ id: 'BlogPage.RecentDataDescriptionSection2' });

  const RecentData = [
    {
      category: RecentDataCategorySection,
      date: '30 October 2024',
      title: RecentDataTitleSection,
      description: RecentDataDescriptionSection,
      image: blog,
    },
    {
      category: RecentDataCategorySection1,
      date: '18 September 2024',
      title: RecentDataTitleSection1,
      description: RecentDataDescriptionSection1,
      image: blog1,
    },
    {
      category: RecentDataCategorySection2,
      date: '18 August 2024',
      title: RecentDataTitleSection2,
      description: RecentDataDescriptionSection2,
      image: blog2,
    },
  ];

  const paginationLinks =  <PaginationLinks
    className={css.pagination}
    pageName="BlogPage"
    pageSearchParams={{page:1}}
    pagination={10}
  />

  return (
    <section className={css.articleCardBlocks}>
     <div className={css.articlesHeading}>
          <h4>{Title}</h4>
           <p>{Description}</p>
      </div>
        <div className={css.articlesCardsBlock}>
          {RecentData.map((blog, index) => (
            <RecentCard
              key={index}
              category={blog.category}
              date={blog.date}
              title={blog.title}
              description={blog.description}
              image={blog.image}
            />
          ))}
      </div>
         <div className={css.paginationBlock}>
          {paginationLinks}
         </div>

    </section>
  );
};

export default RecentArticleSection;
