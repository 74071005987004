import React from 'react';

export const facebookIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="currentColor"
      viewBox="0 0 32 32"
    >
      <path 
        d="M27.2 1.6H4.8a3.21 3.21 0 00-3.2 3.2v22.4c0 1.762 1.44 3.2 3.2 3.2H16V19.2h-3.2v-3.96H16v-3.28c0-3.462 1.939-5.894 6.025-5.894l2.885.003v4.168h-1.915c-1.59 0-2.195 1.194-2.195 2.3v2.705h4.108L24 19.2h-3.2v11.2h6.4c1.76 0 3.2-1.438 3.2-3.2V4.8c0-1.76-1.44-3.2-3.2-3.2z"
      ></path>
    </svg>
  );
};
