import React from 'react';
import union from '../Assets/union.png';
import icon from '../Assets/icon.svg';
import image from '../Assets/image.png';
import css from '../CustomPartner/CustomPartnership.module.css'
import { PageTitle } from '../../OtherPagesComponents/PageHeadingTitle/PageTitle';
import classNames from 'classnames';
const CummunitySection = () => {
const arr=[
    {
        total:"8,000+",
        title:"Community Partners"
    },
    {
        total:"200+",
        title:"Monthly Site Visits"
    },
    {
        total:"10K+",
        title:"Families Engaged a Month"
    }
]
    return (
        <div className={classNames(css.partnerCardBlock , css.flipCard)}>
        <div className={css.partnerColumn}>
        <div className={css.partnerIconBlock}>
                    <img src={union} alt={'union'} height={50} width={50}/>
          </div>
          <div className={css.middelContentArea}>
            <PageTitle
            title='Community Partners '            
            subLine='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque at porttitor metus, ut eleifend diam. Aliquam at vulputate justo. Nullam dictum facilisis est vel sollicitudin. Nulla sagittis, nunc ut pharetra mollis, risus nulla maximus risus, non condimentum magna turpis id metus. Donec ultrices viverra pellentesque.'
            className={css.midWidth}
        />               
            <div className={css.partnersCounts}>
            {arr?.map((val, index) => {
                            return (
                                <div key={index} className={css.countTextBlock}>
                                <div className={css.totalPartnership}>
                                    {val?.total}
                                </div>
                                <div className={css.titlePrtnership}>{val?.title}</div>
                                </div>
                            );
                        })}
            </div>
            </div>
            <button className={`${css.viewDetailsButton} compareBtn btnBlock btnOutline btnDefault`}
         
          >Become a Community Partner</button>
          
        </div>
        <div className={css.partnerImageBlock}>
            <div className={css.partnerImage}>

            <img src={image} alt="partner"/>
            </div>
        </div>

    </div>
        // <div className="row">
        //      <div className="column">
        //         <img src={image} alt="partner" height={300} width={100}/>
        //     </div>
        //     <div className="column">
        //         <div className={'exp-vector-container'}>
        //             <img src={union} alt={'union'} height={50} width={50}/>
        //         </div>
        //         <div className={'exp-vector-icon'}>
        //             <img src={icon} alt={'icon'} height={50} width={50}/>
        //         </div>
        //         <div>
        //             <div>Community Partners</div>
        //             <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque at porttitor metus, ut eleifend diam. Aliquam at vulputate justo. Nullam dictum facilisis est vel sollicitudin. Nulla sagittis, nunc ut pharetra mollis, risus nulla maximus risus, non condimentum magna turpis id metus. Donec ultrices viverra pellentesque.</p>
        //             <div>
        //                 {arr?.map((val, index) => {
        //                     return (
        //                         <div key={index}>
        //                         <div className="total-partnership">
        //                             {val?.total}
        //                         </div>
        //                         <div className="title-partnership">{val?.title}</div>
        //                         </div>
        //                     );
        //                 })}
        //             </div>
        //             <button className='community-partner-btn'>Become a Community Partner</button>
        //         </div>
        //     </div>

        // </div>
    )

}

export default CummunitySection
