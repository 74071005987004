import React from 'react';
import blog from '../Assets/blog_feat.png';
import blog1 from '../Assets/blog_feat1.png';
import blog2 from '../Assets/blog_feat2.png';
import blog3 from '../Assets/blog_feat3.png';
import { useIntl } from 'react-intl';
import css from './Feature.module.css'
const FeatureCard = ({ date, author, description, image }) => {
  const intl = useIntl();
  return (
    <div className={css.featureCard}>
      <div className={css.featureImage}>
        <img src={image} alt={author}/>
      </div>
      <div className={css.featureContent}>
        <p className={css.featureMeta}>
          {date} · {author}
        </p>
        <p className={css.featreDescription}>{description}</p>
      </div>
    </div>
  );
};

const FeatureSection = () => {
  const intl = useIntl();
  const FeaturesTitle=intl.formatMessage({ id: 'BlogPage.FeaturesTitle' });
  const FeaturesDesc=intl.formatMessage({ id: 'BlogPage.FeaturesDesc' });
  const FeaturesDataAuthorSection=intl.formatMessage({ id: 'BlogPage.FeaturesDataAuthorSection' });
  const FeaturesDataDescriptionSection=intl.formatMessage({ id: 'BlogPage.FeaturesDataDescriptionSection' });
  const FeaturesDataAuthorSection1=intl.formatMessage({ id: 'BlogPage.FeaturesDataAuthorSection1' });
  const FeaturesDataDescriptionSection1=intl.formatMessage({ id: 'BlogPage.FeaturesDataDescriptionSection1' });
  const FeaturesDataAuthorSection2=intl.formatMessage({ id: 'BlogPage.FeaturesDataAuthorSection2' });
  const FeaturesDataDescriptionSection2=intl.formatMessage({ id: 'BlogPage.FeaturesDataDescriptionSection2' });
  const FeaturesDataAuthorSection3=intl.formatMessage({ id: 'BlogPage.FeaturesDataAuthorSection3' });
  const FeaturesDataDescriptionSection3=intl.formatMessage({ id: 'BlogPage.FeaturesDataDescriptionSection3' });



  const FeatureData = [
    {
      date: '2 Feb 2024',
      author: FeaturesDataAuthorSection1,
      description: FeaturesDataDescriptionSection1,
      image: blog1,
    },
    {

      date: '20 January 2024',
      author: FeaturesDataAuthorSection2,
      description: FeaturesDataDescriptionSection2,
      image: blog2,
    },
    {

      date: '15 January 2024',
      author: FeaturesDataAuthorSection3,
      description: FeaturesDataDescriptionSection3,
      image: blog3,
    },
  ];

  return (
    <section>

      <div className={css.articlesHeading}>
        <h4>{FeaturesTitle}</h4>
        <p>
          {FeaturesDesc}
        </p>
      </div>

      <div className={css.articlesListBlock}>
        <div className={css.leftArticle}>
        <div className={css.articleImage}>
           <img src={blog} alt={'Success Stories'}/>
        </div>
        <div className={css.articleDetails}>
          <p>8 February 2024 . {FeaturesDataAuthorSection}</p>
          <h5>{FeaturesDataDescriptionSection}</h5>
        </div>
        </div>
        {/* <div className={css.rightAriticles}> */}
          {FeatureData.map((blog, index) => (
            <FeatureCard
              key={index}
              date={blog.date}
              author={blog.author}
              description={blog.description}
              image={blog.image}
            />
          ))}
      {/* </div> */}
      </div>


    </section>
  );
};

export default FeatureSection;
