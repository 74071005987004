import React from 'react';
import { LayoutSingleColumn, Page } from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';
import FeatureSection from './FeatureSection/FeatureSection';
import RecentArticleSection from './RecentArticleSection/RecentArticleSection';
import ResearchSection from './Research/Research';
import {PageTitle} from '../OtherPagesComponents/PageHeadingTitle/PageTitle';
import css from './Blog.module.css'
import { useIntl } from 'react-intl';
const Blog = () => {
  const intl = useIntl();
  const Title = intl.formatMessage({ id: 'BlogPage.Title' });
  const HighlitedText = intl.formatMessage({ id: 'BlogPage.HighLitedText' });
  const Desc = intl.formatMessage({ id: 'BlogPage.Desc' });
  return (
    <Page title={''} scrollingDisabled={false}>
        <LayoutSingleColumn topbar={<TopbarContainer />} footer={<FooterContainer />}>
      <div className={css.blogPage}>
          <div className={css.headerWrapper}>
          <PageTitle
            title={Title}
            hightlightText={HighlitedText}
            subLine={Desc}
          />
          </div>

            <FeatureSection />
            <RecentArticleSection />
            <ResearchSection />
      </div>
        </LayoutSingleColumn>
    </Page>
  );
};
export default Blog;
