import React from 'react';
import image from '../Assets/image1.png'
import { PageTitle } from '../../OtherPagesComponents/PageHeadingTitle/PageTitle';
import css from './CustomPartnership.module.css';
import { useIntl } from 'react-intl';
const CustomPartner = () => {
    const intl=useIntl()
const arr=[
    {
        total:intl.formatMessage({id:'PartnerPage.CustomPartnershipDataTotalSection'}),
        title:intl.formatMessage({id:'PartnerPage.CustomPartnershipDataTitalSection'})
    },
    {
        total:intl.formatMessage({id:'PartnerPage.CustomPartnershipDataTotalSection1'}),
        title:intl.formatMessage({id:'PartnerPage.CustomPartnershipDataTitalSection1'})
    },
    {
        total:intl.formatMessage({id:'PartnerPage.CustomPartnershipDataTotalSection2'}),
        title:intl.formatMessage({id:'PartnerPage.CustomPartnershipDataTitalSection2'})
    },
]
    return (
        <div className={css.partnerCardBlock}>
            <div className={css.partnerColumn}>
                <PageTitle
                    title={intl.formatMessage({id:'PartnerPage.CustomPartnershipTitle'})}
                    subLine={intl.formatMessage({id:'PartnerPage.CustomPartnershipDescription'})}
                    className={css.midWidth}
                />
                <div className={css.partnersCounts}>
                    {arr?.map((val, index) => {
                        return (
                            <div key={index} className={css.countTextBlock}>
                            <div className={css.totalPartnership}>
                                {val?.total}
                            </div>
                            <div className={css.titlePrtnership}>{val?.title}</div>
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className={css.partnerImageBlock}>
                <div className={css.partnerImage}>
                <img src={image} alt="partner"/>
                </div>
            </div>

        </div>
    )

}

export default CustomPartner
