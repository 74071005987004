import React from 'react';
import lifestyleAsst from '../Assets/lifestyleAsst.png';
import css from '../CareAsstment/CareAsstment.module.css';
import { PageTitleRev } from '../../OtherPagesComponents/PageHeadingTitle/PageTitle';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
const LifeStyleAsstment = () => {
  const intl = useIntl()
  const LifeStyleAsstmentTitle = intl.formatMessage({id:'ResourcePage.LifeStyleAsstmentTitle'})
    const LifeStyleAsstmentHighLitedText = intl.formatMessage({id:'ResourcePage.LifeStyleAsstmentHighLitedText'})
    const LifeStyleAsstmentDescription = intl.formatMessage({id:'ResourcePage.LifeStyleAsstmentDescription'})
    const LifeStyleAsstmentBannerDescription = intl.formatMessage({id:'ResourcePage.LifeStyleAsstmentBannerDescription'})
    const LifeStyleAsstmentDataSection = intl.formatMessage({id:'ResourcePage.CareAsstmentDataSection'})
    const LifeStyleAsstmentDataSection1 = intl.formatMessage({id:'ResourcePage.LifeStyleAsstmentDataSection1'})
    const LifeStyleAsstmentDataSection2 = intl.formatMessage({id:'ResourcePage.LifeStyleAsstmentDataSection2'})
    const LifeStyleAsstmentDataSection3 = intl.formatMessage({id:'ResourcePage.LifeStyleAsstmentDataSection3'})
    const LifeStyleAsstmentDataSection4 = intl.formatMessage({id:'ResourcePage.LifeStyleAsstmentDataSection4'})
    const LifeStyleAsstmentDataSection5 = intl.formatMessage({id:'ResourcePage.LifeStyleAsstmentDataSection5'})
    const LifeStyleAsstmentDataSection6 = intl.formatMessage({id:'ResourcePage.LifeStyleAsstmentDataSection6'})
    const LifeStyleAsstmentDataSection7 = intl.formatMessage({id:'ResourcePage.LifeStyleAsstmentDataSection7'})
    const LifeStyleAsstmentDescriptionSection = intl.formatMessage({id:'ResourcePage.LifeStyleAsstmentDescriptionSection'})
    const LifeStyleAsstmentButton = intl.formatMessage({id:'ResourcePage.LifeStyleAsstmentButton'})

  const arr = [
    LifeStyleAsstmentDataSection,
    LifeStyleAsstmentDataSection1,
    LifeStyleAsstmentDataSection2,
    LifeStyleAsstmentDataSection3,
    LifeStyleAsstmentDataSection4,
    LifeStyleAsstmentDataSection5,
    LifeStyleAsstmentDataSection6,
    LifeStyleAsstmentDataSection7,
  ];
  return (
    <div className={css.resourcesToolsBlock}>
      <div className={css.toolListcolumn}>
        <div className={css.headerBlock}>
          <PageTitleRev
            hightlightText={LifeStyleAsstmentHighLitedText}
            title={LifeStyleAsstmentTitle}
            subLine={LifeStyleAsstmentDescription}
          />
        </div>
        <div className={css.toolPoints}>
          {arr?.map((val, index) => {
            return (
              <div key={index} className={css.CareAsstCheckboxContainer}>
                <input type="checkbox" defaultChecked name={val} />
                <label htmlFor={val} className="life-style-value">
                  {val}
                </label>
              </div>
            );
          })}
        </div>
        <p>
          {LifeStyleAsstmentDescriptionSection}
        </p>
        <button className={`${css.viewDetailsButton}  btnBlock btnOutline btnDefault`}>
          {LifeStyleAsstmentButton}
        </button>
      </div>
      <div className={classNames(css.imageColumn , css.topPostion) }>
        <div className={css.imageBlock}>
          <img src={lifestyleAsst} alt="test" height={300} width={100} className="life-style-img" />
        </div>
        <h3>{LifeStyleAsstmentBannerDescription}</h3>
      </div>
    </div>
  );
};
export default LifeStyleAsstment;
