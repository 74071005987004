import React from 'react';
import loc1 from '../Assets/loc1.png';
import loc2 from '../Assets/loc2.png';
import loc3 from '../Assets/loc3.png';
import css from './LocationsSection.module.css';
import ButtonComponent from '../ButtonComponent/ButtonComponent';
import { useIntl } from 'react-intl';

const LocationsSection = () => {
  const intl = useIntl();

  const locations = [
    { name: 'Georgia', image: loc1 },
    { name: 'Texas', image: loc2 },
    { name: 'Maryland', image: loc3 },
  ];

  return (
    <section className={css.popularLocation}>
      <div className={css.locationsSection}>
        <div className={css.textContainer}>
          <div className={css.locationHeading}>
            <div className={css.leftSideHeading}>
              <h2>
                <span>{intl.formatMessage({ id: 'LocationsSection.PopularLocations' })}</span>
                {intl.formatMessage({ id: 'LocationsSection.DiscoverLocations' })}
              </h2>
            </div>
            <div className={css.rightSideContent}>
              <p>{intl.formatMessage({ id: 'LocationsSection.Description' })}</p>
              <ButtonComponent
                value={intl.formatMessage({ id: 'LocationsSection.LearnMore' })}
              ></ButtonComponent>
            </div>
          </div>
        </div>
        <div className={css.locationsGrid}>
          {locations.map((location, index) => (
            <div key={index} className={css.locationCard}>
              <div className={css.locationImageBlock}>
                <img src={location.image} alt={location.name} className={css.locationImage} />
              </div>
              <p className={css.locationName}>{location.name}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default LocationsSection;
