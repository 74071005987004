import React from 'react';
import helpOne from '../Assets/helpOne.png';
import helpTwo from '../Assets/helpTwo.png';
import helpThree from '../Assets/helpThree.png';
import helpFour from '../Assets/helpFour.png';
import HeadingComponent from '../HeadingBlockComp/HeadingComponent';
import classNames from 'classnames';
import css from './HowWeHelp.module.css';
import { useIntl } from 'react-intl';

const StepCard = ({ image, hightLigthTitle, title, description, counting, index }) => {
  return (
    <div className={css.stepCard}>
      <div className={css.iconsBlock}>
        <img src={image} alt={title} className={css.stepImage} />
      </div>
      <div
        className={classNames(css.infoBlock, {
          [css.designDifferent]: index === 3,
        })}
      >
        <h3 className={css.stepTitle}>
          <span>{hightLigthTitle}</span> {title}
        </h3>
        <p className={css.stepDescription}>{description}</p>
      </div>
      <div className={css.countBlock}>
        <p>{counting}</p>
      </div>
    </div>
  );
};

const HowWeHelp = () => {
  const intl = useIntl();

  const steps = [
    {
      image: helpOne,
      hightLigthTitle: intl.formatMessage({ id: 'HowWeHelp.Step1.Highlight' }),
      title: intl.formatMessage({ id: 'HowWeHelp.Step1.Title' }),
      counting: '1',
      description: intl.formatMessage({ id: 'HowWeHelp.Step1.Description' }),
    },
    {
      image: helpTwo,
      hightLigthTitle: intl.formatMessage({ id: 'HowWeHelp.Step2.Highlight' }),
      title: intl.formatMessage({ id: 'HowWeHelp.Step2.Title' }),
      counting: '2',
      description: intl.formatMessage({ id: 'HowWeHelp.Step2.Description' }),
    },
    {
      image: helpThree,
      hightLigthTitle: intl.formatMessage({ id: 'HowWeHelp.Step3.Highlight' }),
      title: intl.formatMessage({ id: 'HowWeHelp.Step3.Title' }),
      counting: '3',
      description: intl.formatMessage({ id: 'HowWeHelp.Step3.Description' }),
    },
    {
      image: helpFour,
      hightLigthTitle: intl.formatMessage({ id: 'HowWeHelp.Step4.Highlight' }),
      title: intl.formatMessage({ id: 'HowWeHelp.Step4.Title' }),
      counting: '4',
      description: intl.formatMessage({ id: 'HowWeHelp.Step4.Description' }),
    },
  ];

  return (
    <section className={css.howWeHelp}>
      <div className={css.container}>
        <div className={`${css.sectionHeader} ${css.mediumWidth} text-center`}>
          <HeadingComponent
            title={intl.formatMessage({ id: 'HowWeHelp.Title' })}
            subTitle={intl.formatMessage({ id: 'HowWeHelp.SubTitle' })}
          />
        </div>
        <div className={`${css.stepList} d-grid`}>
          {steps.map((step, index) => (
            <StepCard
              key={index}
              image={step.image}
              hightLigthTitle={step.hightLigthTitle}
              title={step.title}
              description={step.description}
              counting={step.counting}
              index={index}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default HowWeHelp;
