import React from 'react';
import union from '../Assets/union.png';
import union1 from '../Assets/union1.png';
import union2 from '../Assets/union2.png';
import union3 from '../Assets/union3.png';
import icon from '../Assets/icon.svg';
import icon1 from '../Assets/icon1.svg';
import icon2 from '../Assets/icon2.svg';
import icon3 from '../Assets/icon3.svg';
import { useIntl } from 'react-intl';
import css from './ExploreSection.module.css';
import { PageTitle } from '../../OtherPagesComponents/PageHeadingTitle/PageTitle';
const ExploreCard = ({ union,vector,title }) => {
  const intl = useIntl();
  return (
    <div className={css.expCard}>
      <div className={css.expVectorContainer}>
        <img src={union} alt={title}/>
      </div>
      {/* <div className={'exp-vector-icon'}>
        <img src={vector} alt={title} height={50} width={50}/>
      </div> */}
      <div className={'title'}>
        {title}
      </div>
    </div>
  );
};

const ExploreSection = () => {
  const intl = useIntl();

  const ExploreData = [
    {
      union: union,
      vector: icon,
      title:intl.formatMessage({id:'PartnerPage.ExploreDataTitleSection'})
    },
    {
        union: union1,
        vector: icon1,
        title:intl.formatMessage({id:'PartnerPage.ExploreDataTitleSection1'})
    },
    {
        union: union2,
        vector: icon2,
        title:intl.formatMessage({id:'PartnerPage.ExploreDataTitleSection2'})
    },
    {
        union: union3,
        vector: icon3,
        title:intl.formatMessage({id:'PartnerPage.ExploreDataTitleSection3'})
     }

  ];

  return (
    <section>
      <div className={css.headerExpPartnership}>
      <PageTitle
            title={intl.formatMessage({id:'PartnerPage.ExploreTitle'})}
            subLine={intl.formatMessage({id:'PartnerPage.ExploreDescription'})}
            className={css.maxWidth}
          />
      </div>

      <div className={css.expData}>
          {ExploreData.map((exp, index) => (
            <ExploreCard
              key={index}
              union={exp?.union}
              vector={exp?.vector}
              title={exp?.title}
            />
          ))}

      </div>
    </section>
  );
};

export default ExploreSection;
