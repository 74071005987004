import React, { useEffect, useState } from 'react';
import css from './CustomeFilterStyle.module.css';
import IconArrowUp from '../../../components/IconArrowUp/IconArrowUp';
import IconSingleStar from '../../../components/IconSingleStar/IconSingleStar';
import CustomCheckBox from './CustomCheckBox';
import IconDownArrow from '../../../components/IconDownArrow/IconDownArrow';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { createResourceLocatorString } from '../../../util/routes';
import { performSearch } from './LocationHelper';
import { useRouteConfiguration } from '../../../context/routeConfigurationContext';
const LocationClose = (props) => {
  const { urlQueryParams } = props;
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);
  const searchRadius = searchParams.get('searchClose');
  const [selected, setSelected] = useState(searchRadius);
  const routeConfiguration = useRouteConfiguration();
  const locationOptions = [
    {
      value: "silverSprings",
      origin: {lat: 38.99066570000001,lng: -77.026088},
      filterText: 'Silver Springs',
    },
    {
      value: "marietta",
      origin: {lat: 33.95260200000001,lng: -84.5499327},
      filterText: 'Marietta',
    },
    {
      value: "decatur",
      origin: {lat: 33.7748275,lng: -84.2963123},
      filterText: 'Decatur',
    },
  ];

  useEffect(()=>{
    if(selected != searchParams.get('searchClose')){
      setSelected(searchParams.get('searchClose'))
    }
  },[searchParams.get('searchClose')])
  
  useEffect(() => {
    if (selected) {
      console.log("selected",locationOptions?.find(l=> l.value === selected)?.origin)
      const search = {
        ...urlQueryParams,
        ...performSearch(selected, locationOptions?.find(l=> l.value === selected)?.origin, 10, 'searchClose'),
      };
      delete search.searchRadii;
      history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, search));
    }
  }, [selected]);

  const [isOpen, setIsOpen] = useState(true);
  const [arrow, setArrow] = useState(true);

  const openFilterHandler = () => {
    setIsOpen(!isOpen);
    setArrow(!arrow);
  };

  const handleChange = (e)=>{
    const isChecked = e.target.checked;
    if (isChecked) {
      setSelected(e.target.value);
    } else{
      setSelected();
      const search = JSON.parse(JSON.stringify(urlQueryParams));
      delete search.origin;
      delete search.bounds;
      delete search.searchClose
      history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, search));
    }
  }

  return (
    <>
      <div className={css.filterHeader}>
        <button type="button" className={css.labelButton} onClick={openFilterHandler}>
          <span className={css.labelButtonContent}>
            <span className={css.labelWrapper}>
              <span className={css.label}>Locations close by</span>
            </span>
            <span className={css.openSign}>{arrow ? <IconArrowUp /> : <IconDownArrow />}</span>
          </span>
        </button>
      </div>

      {isOpen ? (
        <div className={css.filterLists}>
          <ul>
            {locationOptions.map(items => (
              <li key={items.id}>
                <span className={css.customeCheckBox}>
                  <CustomCheckBox
                    checked={selected == items?.value}
                    value={items.value}
                    onChange={handleChange}
                  />
                </span>
                <span className={css.textRoot}>
                  {items.star} {items.filterText}
                </span>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        ' '
      )}
    </>
  );
};

export default LocationClose;
