import React, { useState, useEffect } from 'react';
import css from './CustomeFilterStyle.module.css';
import IconArrowUp from '../../../components/IconArrowUp/IconArrowUp';
import IconSingleStar from '../../../components/IconSingleStar/IconSingleStar';
import CustomCheckBox from './CustomCheckBox';
import IconDownArrow from '../../../components/IconDownArrow/IconDownArrow';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { createResourceLocatorString } from '../../../util/routes';
import { useRouteConfiguration } from '../../../context/routeConfigurationContext';

const RatingFilter = props => {
  const routeConfiguration = useRouteConfiguration();
  const { urlQueryParams } = props;
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);
  const searchRadius = searchParams.get('pub_avgStarRating');
  
  // Initialize state with localStorage value or fallback to searchRadius
  const [selected, setSelected] = useState(localStorage.getItem('selectedCheckbox') || searchRadius);

  const bestFilter = [
    {
      value: JSON.stringify({ pub_avgStarRating: `4,6` }),
      star: <IconSingleStar />,
      filterText: '4 stars or upper',
    },
    {
      value: JSON.stringify({ pub_isFeatured: true }),
      star: '',
      filterText: 'Featured',
    },
    {
      value: JSON.stringify({ pub_isBestseller: true }),
      star: '',
      filterText: 'Best Seller',
    },
    {
      value: JSON.stringify({ pub_isDiscounted: true }),
      star: '',
      filterText: 'Discount',
    },
  ];

  const [isOpen, setIsOpen] = useState(true);
  const [arrow, setArrow] = useState(true);

  const openFilterHandler = () => {
    setIsOpen(!isOpen);
    setArrow(!arrow);
  };

  const handleChange = e => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setSelected(e.target.value);
      localStorage.setItem('selectedCheckbox', e.target.value); // Save to localStorage
      const parsedValue = JSON.parse(e.target.value);
      const search = {
        ...urlQueryParams,
        ...parsedValue,
      };
      history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, search));
    } else {
      const checkedValue = JSON.parse(selected);
      const search = urlQueryParams;
      delete search[Object.keys(checkedValue)?.[0]];
      setSelected(null); // Clear selection
      localStorage.removeItem('selectedCheckbox'); // Remove from localStorage
      history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, search));
    }
  };

  // Effect to load selected checkbox state on mount
  useEffect(() => {
    const storedValue = localStorage.getItem('selectedCheckbox');
    if (storedValue) {
      setSelected(storedValue);
    }
  }, []);

  return (
    <>
      <div className={css.filterHeader}>
        <button type="button" className={css.labelButton} onClick={openFilterHandler}>
          <span className={css.labelButtonContent}>
            <span className={css.labelWrapper}>
              <span className={css.label}>Best Filter</span>
            </span>
            <span className={css.openSign}>{arrow ? <IconArrowUp /> : <IconDownArrow />}</span>
          </span>
        </button>
      </div>

      {isOpen ? (
        <div className={css.filterLists}>
          <ul>
            {bestFilter.map(items => (
              <li key={items.value}>
                <span className={css.customeCheckBox}>
                  <CustomCheckBox
                    checked={selected === items.value}
                    value={items.value}
                    onChange={handleChange}
                  />
                </span>
                <span className={css.textRoot}>
                  {items.star} {items.filterText}
                </span>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        ' '
      )}
    </>
  );
};

export default RatingFilter;
