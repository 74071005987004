// import React from 'react'
import css from './ButtonCompnenStyle.module.css'
// const ButtonComponent = ({value, type, className}) => {
//   return (
//     <>
//       <button className={css.classNames} type={type}>{value}</button>  
//     </>
//   )
// }
import React from 'react';
import classNames from 'classnames';
import styles from './ButtonCompnenStyle.module.css'

const ButtonComponent = ({ 
    value, 
    customClass, // Expecting the key of a CSS module class
    variant = 'primary', 
    disabled = false, 
    onClick,
    icon
}) => {
  const buttonClass = classNames(
    styles.baseBtn, // Permanent base class
    styles[variant], // Dynamic variant class (e.g., primary, secondary)
    { [styles.disabled]: disabled }, // Conditional class for disabled
    styles[customClass] // Dynamically apply the custom class from CSS module
  
  );

  return (
    <button 
      className={`${buttonClass} ${variant==="primary" ? '' : customClass=="compare" ? css.whitebg : ''}`} 
      onClick={onClick} 
      disabled={disabled}

    >
      {value}
      {icon}
    </button>
  );
};



export default ButtonComponent
