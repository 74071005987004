import React, { useEffect } from 'react';

const GoogleTagManager = () => {
  useEffect(() => {
    // Add the Google Tag script to the document head
    const script1 = document.createElement('script');
    const script2 = document.createElement('script');
    script1.src = 'https://www.googletagmanager.com/gtag/js?id=AW-11028000563';
    script1.async = true;
    document.head.appendChild(script1);

    script1.onload = () => {
      script2.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'AW-11028000563');
      `;
      document.head.appendChild(script2);
    };

    // Cleanup on component unmount
    return () => {
      document.head.removeChild(script1);
      document.head.removeChild(script2);
    };
  }, []);

  return null; // This component doesn't render anything
};

export default GoogleTagManager;
