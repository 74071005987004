import React from 'react'

const IconMap = () => {
  return (
    <>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path fill-rule="evenodd" clip-rule="evenodd" d="M15.2717 11.6852L15.2743 11.688L15.2754 11.6891C15.4641 11.8875 15.7262 12 16 12C16.2738 12 16.5362 11.8872 16.7249 11.6888L16.7257 11.688L16.7283 11.6852L16.7373 11.6758L16.7695 11.6414C16.7972 11.6118 16.837 11.5691 16.8871 11.5145C16.9873 11.4053 17.1292 11.2485 17.2989 11.0549C17.6375 10.6686 18.0913 10.1305 18.5474 9.52784C19.0007 8.92886 19.4719 8.24567 19.8341 7.57102C20.1793 6.92832 20.5 6.16039 20.5 5.42857C20.5 2.9634 18.4657 1 16 1C13.5343 1 11.5 2.9634 11.5 5.42857C11.5 6.16039 11.8207 6.92832 12.1659 7.57102C12.5281 8.24567 12.9993 8.92886 13.4526 9.52784C13.9087 10.1305 14.3625 10.6686 14.7011 11.0549C14.8708 11.2485 15.0127 11.4053 15.1129 11.5145C15.163 11.5691 15.2028 11.6118 15.2305 11.6414L15.2627 11.6758L15.2717 11.6852ZM16 3C14.5997 3 13.5 4.10665 13.5 5.42857C13.5 5.64353 13.6168 6.04542 13.9279 6.62488C14.2219 7.17239 14.6257 7.76371 15.0474 8.32089C15.3813 8.76212 15.7167 9.16922 16 9.49986C16.2833 9.16922 16.6187 8.76212 16.9526 8.32089C17.3743 7.76371 17.7781 7.17239 18.0721 6.62488C18.3832 6.04542 18.5 5.64353 18.5 5.42857C18.5 4.10665 17.4002 3 16 3Z" fill="#262BBA"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M2.31623 5.116C2.01128 5.01435 1.67606 5.06549 1.41529 5.25344C1.15452 5.44139 1 5.74324 1 6.06468V20.0647C1 20.4951 1.27543 20.8773 1.68377 21.0134L4.54011 21.9655L4.72046 22.0257C6.09619 22.485 7.05225 22.8042 8.04753 22.7334C9.04281 22.6627 9.94412 22.2115 11.2411 21.5622L11.4111 21.4772L13.4833 20.441C15.0187 19.6734 15.5452 19.4299 16.0943 19.3909C16.6434 19.3519 17.1989 19.5184 18.8274 20.0613L21.6838 21.0134C21.9887 21.115 22.3239 21.0639 22.5847 20.8759C22.8455 20.688 23 20.3861 23 20.0647V8.06468H21V18.6773L19.4599 18.1639L19.2795 18.1037C18.3841 17.8047 17.6664 17.5651 17 17.4538V14H15V17.5777C14.333 17.7791 13.6366 18.1278 12.7589 18.5671L12.5889 18.6522L10.5167 19.6883C9.84827 20.0225 9.37109 20.2574 9 20.4192V8.55227C9.59619 8.37236 10.2075 8.0761 10.9449 7.70979L10.0551 5.91862C8.85828 6.51315 8.38003 6.70475 7.90575 6.73846C7.35665 6.77748 6.80107 6.61095 5.17257 6.06811L2.31623 5.116ZM7 8.67557V20.6355C6.58267 20.533 6.0244 20.3521 5.17257 20.0681L3 19.3439V7.45211L4.54011 7.96548L4.72048 8.02566C5.61593 8.32462 6.33359 8.56423 7 8.67557Z" fill="#262BBA"/>
</svg> 
    </>
  )
}

export default IconMap
