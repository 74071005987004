import React from 'react'

const IconArrowUp = () => {
  return (
    <>
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
<path d="M9.96004 7.5249L6.70004 4.2649C6.31504 3.8799 5.68504 3.8799 5.30004 4.2649L2.04004 7.5249" stroke="#0B0F0E" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
</svg> 
    </>
  )
}

export default IconArrowUp
