import React from 'react';

const IconLocation = () => {
  return (
    <>
      <svg
        width="16"
        height="20"
        viewBox="0 0 16 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 10C9.1 10 10 9.1 10 8C10 6.9 9.1 6 8 6C6.9 6 6 6.9 6 8C6 9.1 6.9 10 8 10ZM8 0C12.2 0 16 3.22 16 8.2C16 11.38 13.55 15.12 8.66 19.43C8.28 19.76 7.71 19.76 7.33 19.43C2.45 15.12 0 11.38 0 8.2C0 3.22 3.8 0 8 0Z"
          fill="#222430"
        />
      </svg>
    </>
  );
};

export default IconLocation;
