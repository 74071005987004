import React from 'react';
import blog1 from '../Assets/blog1.png';
import blog2 from '../Assets/blog2.png';
import blog3 from '../Assets/blog3.png';
import css from './BlogSection.module.css';
import { useIntl } from 'react-intl';
import HeadingComponent from '../HeadingBlockComp/HeadingComponent';
import ButtonComponent from '../ButtonComponent/ButtonComponent';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const BlogCard = ({ title, date, author, description, image }) => {
  return (
    <div className={css.blogCard}>
      <div className={css.blogImage}>
        <img src={image} alt={title} />
      </div>
      <div className={css.blogContent}>
        <p className={css.blogMeta}>
          {date} · {author}
        </p>
        <h3 className={css.blogTitle}>{title}</h3>
        <p className={css.blogDescription}>{description}</p>
      </div>
    </div>
  );
};

const BlogSection = () => {
  const intl = useIntl();

  const blogData = [
    {
      title: intl.formatMessage({ id: 'CustomHomePage.HowToChooseRightSeniorCare' }),
      date: 'Sep 31, 2023',
      author: 'Erin Bator',
      description: intl.formatMessage({
        id: 'CustomHomePage.HowToChooseRightSeniorCareDescription',
      }),
      image: blog1,
    },
    {
      title: intl.formatMessage({ id: 'CustomHomePage.EasingTransitionSeniorCare' }),
      date: 'Sep 31, 2023',
      author: 'Erin Bator',
      description: intl.formatMessage({
        id: 'CustomHomePage.EasingTransitionSeniorCareDescription',
      }),
      image: blog2,
    },
    {
      title: intl.formatMessage({ id: 'CustomHomePage.BenefitsSeniorCommunityLiving' }),
      date: 'Sep 31, 2023',
      author: 'Erin Bator',
      description: intl.formatMessage({
        id: 'CustomHomePage.BenefitsSeniorCommunityLivingDescription',
      }),
      image: blog3,
    },
  ];
const history=useHistory()

  return (
    <section className={css.blogSection}>
      <div className={css.blogHeader}>
        <HeadingComponent
          mainTitle={intl.formatMessage({ id: 'CustomHomePage.BlogTitle' })}
          title={intl.formatMessage({ id: 'CustomHomePage.YourGuideTo' })}
          subTitle={intl.formatMessage({ id: 'CustomHomePage.BlogSubtitle' })}
          highlightText={intl.formatMessage({ id: 'CustomHomePage.SeniorCare' })}
        />
      </div>

      <div className={css.blogList}>
        {blogData.map((blog, index) => (
          <BlogCard
            key={index}
            title={blog.title}
            date={blog.date}
            author={blog.author}
            description={blog.description}
            image={blog.image}
          />
        ))}
      </div>

      <div className={css.viewMoreButton}
       onClick={()=> history.push('/blog')}
      >
        <ButtonComponent
          value={intl.formatMessage({ id: 'CustomHomePage.ViewMore' })}
          variant="secondary"
        />
      </div>
    </section>
  );
};

export default BlogSection;
