import React from 'react';
import PropTypes from 'prop-types';

const Iconbackquote = props => {
  const { className, rootClassName } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="101"
      height="100"
      viewBox="0 0 101 100"
      fill="none"
    >
      <path
        d="M75.2701 81.2866C71.1936 81.2866 67.5758 80.2166 64.4165 78.0764C61.2573 75.9363 58.7605 73.0319 56.9261 69.3631C55.0917 65.6943 54.1745 61.6178 54.1745 57.1338C54.1745 49.7962 56.5694 42.6115 61.3592 35.5796C66.2509 28.4459 73.1299 22.586 81.9962 18L99.1172 27.172C93.3083 30.0255 88.3656 33.3376 84.2892 37.1083C80.3146 40.879 77.614 45.3121 76.1872 50.4076C80.9771 50.8153 84.8497 52.293 87.8051 54.8408C90.8624 57.2866 92.3911 60.7006 92.3911 65.0828C92.3911 69.465 90.8115 73.2866 87.6522 76.5478C84.5949 79.707 80.4675 81.2866 75.2701 81.2866ZM24.2127 81.2866C20.1363 81.2866 16.5185 80.2166 13.3592 78.0764C10.2 75.9363 7.70317 73.0319 5.86878 69.3631C4.03439 65.6943 3.11719 61.6178 3.11719 57.1338C3.11719 49.7962 5.51209 42.6115 10.3019 35.5796C15.1936 28.4459 22.0726 22.586 30.9388 18L48.0599 27.172C42.2509 30.0255 37.3083 33.3376 33.2318 37.1083C29.2573 40.879 26.5567 45.3121 25.1299 50.4076C29.9197 50.8153 33.7923 52.293 36.7478 54.8408C39.8051 57.2866 41.3338 60.7006 41.3338 65.0828C41.3338 69.465 39.7541 73.2866 36.5949 76.5478C33.5376 79.707 29.4102 81.2866 24.2127 81.2866Z"
        fill="#262BBA"
      />
    </svg>
  );
};

const { string } = PropTypes;

Iconbackquote.defaultProps = {
  className: null,
  rootClassName: null,
};

Iconbackquote.propTypes = {
  className: string,
  rootClassName: string,
};

export default Iconbackquote;
