import React, { useRef, useState } from 'react';
import { LayoutSingleColumn, Page } from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';
import CustomPartner from './CustomPartner/CustomPartnership';
import ExploreSection from './ExploreSection/ExploreSection';
import CummunitySection from './Community/CommunitySection';
import PartnerWithUs from './PartnerWithUs/PartnerWithUs';
import HomeCareSection from './HomeCareSection/HomeCareSection';
import Partnership from './PartnershipSection/PartnershipSection';
import QuestionSection from './QuestionSection/QuestionSection';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { connect, useDispatch } from 'react-redux';
import { func } from 'prop-types';
import { questionSubmit } from './PaertnerPage.duck';
import css from './PartnerPageStyle.module.css'

const handleChange = ([field], state) => {
  const tfield = state.fields["company"];
  tfield.change(field.target.value)
}
const PartnerPageComponent = props => {
  const [message,setMessage] = useState({})
  let companySelectRef = useRef(null);
  const dispatch = useDispatch();
  const QuestionSubmit = async (values, form) => {
    companySelectRef.current.value = ''
    form.reset();
    await dispatch(questionSubmit(values)).then((res)=>{
       setMessage(res)
    }).catch((err)=>{
      console.log("err",err)
      setMessage(err)
    })

  }

  return (
    <Page title={''} scrollingDisabled={false}>
        <LayoutSingleColumn topbar={<TopbarContainer />} footer={<FooterContainer />}>
        <div className={css.partnerPage}>
            <CustomPartner />
            <ExploreSection />
            <CummunitySection />
            <PartnerWithUs />
            <div className={css.bgColor}>
            <HomeCareSection />
            <Partnership />
            </div>

            <QuestionSection
              onSubmit={QuestionSubmit}
              mutators={{
                handleChange
            }}
            response={message}
            companySelectRef={companySelectRef}
            />

        </div>
        </LayoutSingleColumn>
    </Page>
  );
};

PartnerPageComponent.defaultProps = {
  resetPasswordError: null,
};

PartnerPageComponent.propTypes = {
  // scrollingDisabled: bool.isRequired,
  // resetPasswordInProgress: bool.isRequired,
  // resetPasswordError: propTypes.error,
  // onSubmitQuestion: func.isRequired,

  // // from withRouter
  // location: shape({
  //   search: string,
  // }).isRequired,

  // // from injectIntl
  // intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  // const { resetPasswordInProgress, resetPasswordError } = state.PasswordResetPage;
  // return {
  //   scrollingDisabled: isScrollingDisabled(state),
  //   resetPasswordInProgress,
  //   resetPasswordError,
  // };
  return{}
};

const mapDispatchToProps = dispatch => ({
  // onSubmitQuestion:async(values) => await dispatch(questionSubmit(values))

});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const PartnerPage = compose(
  withRouter,
  connect       (
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(PartnerPageComponent);

export default PartnerPage;
