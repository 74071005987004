import React, { useEffect, useState } from 'react';
import { types as sdkTypes } from '../../../../util/sdkLoader';
import { Modal, NamedLink, ResponsiveImage } from '../../../../components';
import { useDispatch } from 'react-redux';
import { compareDataApi } from '../../SearchPage.duck';
import css from './ComparePopUp.module.css';
import { ensureListing } from '../../../../util/data';
import { richText } from '../../../../util/richText';
import { formatMoney } from '../../../../util/currency';
import { asyncFetchReviews } from '../../../ListingPage/ListingPage.duck';
import AvgReviewRating from '../../../../components/ReviewRating/AvgReviewRating';
import { lazyLoadWithDimensions } from '../../../../util/uiHelpers';
import { useConfiguration } from '../../../../context/configurationContext';
import ButtonComponent from '../../../LandingPageCustom/ButtonComponent/ButtonComponent';
import { createSlug } from '../../../../util/urlHelpers';

const { Money } = sdkTypes;

const ComparePopUp = ({
  showComparePopup,
  setShowComparePopup,
  selectedListings,
  setSelectedListings,
  onManageDisableScrolling,
  config,
  intl,
}) => {
  const dispatch = useDispatch();
  const [compareData, setCompareData] = useState([]);
  const [reviews, setReviews] = useState({});
  // const config =useConfiguration()
  useEffect(() => {
    const listingIds = selectedListings?.map(listing => listing.id);
    dispatch(compareDataApi({ listingIds })).then(res => {
      if (res?.status === 'success') {
        let data = res?.listingDatas?.data?.data;
        const structuredData = data.map(item => {
          const listingImage = res?.listingDatas?.data?.included?.find(
            data =>
              data.type === 'image' &&
              data.id.uuid === item?.relationships?.images?.data?.[0]?.id?.uuid
          );

          const currentListing = ensureListing(item);
          const { price } = currentListing?.attributes;
          const {
            pricePerMonth,
            listingType,
            caretypes,
            categoryLevel1,
          } = currentListing?.attributes?.publicData;

          const formattedPrice =
            price && price?.currency === config?.currency
              ? formatMoney(intl, new Money(price?.amount, price?.currency))
              : price?.currency
                ? price?.currency
                : 'N/A';

          const formattedPricePerMonth =
            pricePerMonth && pricePerMonth?.currency === config?.currency
              ? formatMoney(intl, new Money(pricePerMonth?.amount, pricePerMonth?.currency))
              : pricePerMonth?.currency
                ? pricePerMonth?.currency
                : 'N/A';

          const listingTypeData = config.listing.listingTypes?.find(
            dataType => dataType.listingType === listingType
          );

          const careTypes = config.listing.listingFields
            .find(data => (data.key = 'caretypes'))
            .enumOptions?.map(cnf => {
              return caretypes?.includes(cnf.option) ? cnf.label : '';
            });
          return {
            id: currentListing?.id?.uuid,
            title: currentListing?.attributes?.title || 'N/A',
            address: currentListing?.attributes?.publicData?.location?.address || 'N/A',
            amenities_services: currentListing?.attributes?.publicData?.amenities_services || 'N/A',
            bedcapacity: currentListing?.attributes?.publicData?.bedcapacity || 'N/A',

            price: formattedPrice,
            monthPrice: formattedPricePerMonth,
            careTypes: careTypes.filter(data => data != '')?.join(', ') || 'N/A',
            listingImage: listingImage || 'N/A',
            listingType: listingTypeData?.label || 'N/A',
            categoriesLabel: categoryLevel1 || 'N/A',
          };
        });

        setCompareData(structuredData);
        structuredData?.forEach(listing => {
          dispatch(asyncFetchReviews(listing?.id)).then(reviewData => {
            setReviews(prevState => ({
              ...prevState,
              [listing.id]: reviewData,
            }));
          });
        });
      }
    });
  }, [selectedListings]);

  const MIN_LENGTH_FOR_LONG_WORDS = 10;
  const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

  const handleClose = () => {
    setShowComparePopup(false);
    setSelectedListings([]);
  };
  console.log('listing', compareData)

  return (
    <Modal
      id="SearchPageComparePopup"
      isOpen={showComparePopup}
      onClose={() => handleClose()}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
      lightCloseButton
      isCustomPopup={true}
    // className={css.customModel}
    >
      <div className={css.comparePopUpBlock}>
      {/* <div className={classNames(css.compareCard,'custompopupcard')}>
         <div className={classNames(css.productImageBlock_,'hidefield')} >
         <img src={compareData?.[0]?.listingImage.attributes.variants['listing-card'].url} />
         </div>
         <div className={classNames(css.title,'hidefield')}>212</div>
         <p></p>
         <div className='hidefield'>
         <AvgReviewRating
                  key={`rating-12121`}
                  ratings={ []}
                  className={css.desktopReviewRating}
                  reviewStarClassName={css.reviewRatingStar}
                />
                </div>
         <div className={classNames(css.comparelistPoints,'customcomparepoints')}>
                        <p>Price Per Month:</p>
              <p>Care types offered:</p>
              <p>Amenities: </p>
              <p>Size (# of beds):</p>
            </div>
         
        </div>
         */}

        <div className={css.comparePointsList}>
          <div className={css.pointsList}>
                         <p>Price Per Month:</p>
                        <p>Care types offered: </p>
                        <p>Amenities:</p>
                        <p>Size (# of beds): </p>
          </div>
         
        </div>
        <div className={css.compareListCards}>
        {compareData.length > 0 ? (
          compareData.map((listing, index) => {
            const slug = createSlug(listing.title);

            return (
              <div className={css.compareCard} key={index}>
                <div className={css.productImageBlock_}>
                  <img src={listing.listingImage.attributes.variants['listing-card'].url} />
                  {/* <LazyImage
                    rootClassName={css.rootForImage}
                    alt={listing?.title}
                    image={listing.listingImage}
                    variants={['listing-card', 'listing-card-2x']}
                    sizes={[
                      '(max-width: 549px) 100vw',
                      '(max-width: 767px) 50vw',
                      `(max-width: 1439px) 26vw`,
                      `(max-width: 1920px) 18vw`,
                      `14vw`,
                    ].join(', ')}
                  /> */}
                </div>
                <div className={css.title}>
                  {richText(listing?.title, {
                    longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                    longWordClass: css.longWord,
                  })}
                </div>
                <p>{listing.address}</p>

                <AvgReviewRating
                  key={`rating-${listing.id}`}
                  ratings={reviews?.[listing.id] || []}
                  className={css.desktopReviewRating}
                  reviewStarClassName={css.reviewRatingStar}
                />
                <div className={css.comparelistPoints}>
                  {/* <p>Price: {listing.price}</p> */}
                  <p className={css.monthPrice}>{listing.monthPrice}{index ==0 &&<span>Price Per Month:</span>}</p>
                  <p className={css.careTypes}>{listing.careTypes}{index ==0 &&<span>Care types offered:</span>}</p>
                  <p className={css.amenities_services}> {listing.amenities_services}{index ==0 &&<span>Amenities:</span>}</p>
                  <p className={css.bedcapacity}>{listing.bedcapacity}{index ==0 &&<span>Size (# of beds):</span>}</p>
                </div>
                <NamedLink
                  name="ListingPage"
                  params={{ id: listing.id, slug }}
                >
                  <div className={css.campareProdBtn}>
                    <ButtonComponent value="View Profile" />
                  </div>
                </NamedLink>
              </div>
            )
          }
          )
        ) : (
          <p>No data to compare</p>
        )}
        </div>
      </div>
    </Modal>
  );
};

export default ComparePopUp;
