import React from 'react';
import union from '../Assets/union1.png';
import icon from '../Assets/icon1.svg';
import image from '../Assets/image2.png';
import ButtonComponent from '../../LandingPageCustom/ButtonComponent/ButtonComponent';
import css from '../CustomPartner/CustomPartnership.module.css'
import { PageTitle } from '../../OtherPagesComponents/PageHeadingTitle/PageTitle';
import classNames from 'classnames';
import { useIntl } from 'react-intl';

const HomeCareSection = () => {
    const intl = useIntl();
    const arr = [
        {
            total:intl.formatMessage({id:'PartnerPage.HomeCareDataTotalSection'}),
            title:intl.formatMessage({id:'PartnerPage.HomeCareDataTitalSection'}),
        },
        {
            total:intl.formatMessage({id:'PartnerPage.HomeCareDataTotalSection1'}),
            title:intl.formatMessage({id:'PartnerPage.HomeCareDataTitalSection1'}),
        },
        {
            total:intl.formatMessage({id:'PartnerPage.HomeCareDataTotalSection2'}),
            title:intl.formatMessage({id:'PartnerPage.HomeCareDataTitalSection2'}),
        }
    ]
    return (
        <div className={classNames(css.partnerCardBlock , css.flipCard, css.flipImgCard )}>
        <div className={css.partnerColumn}>
        <div className={css.partnerIconBlock}>
                 <img src={union} alt={'icon'} height={50} width={50}/>
          </div>
          <div className={css.middelContentArea}>
            <PageTitle
            title = {intl.formatMessage({id:'PartnerPage.HomeCareTitle'})}
            subLine = {intl.formatMessage({id:'PartnerPage.HomeCareDescription'})}
            className={css.midWidth}
        />
            <div className={css.partnersCounts}>
            {arr?.map((val, index) => {
                            return (
                                <div key={index} className={css.countTextBlock}>
                                <div className={css.totalPartnership}>
                                    {val?.total}
                                </div>
                                <div className={css.titlePrtnership}>{val?.title}</div>
                                </div>
                            );
                        })}
            </div>
            </div>
            <button className={`${css.viewDetailsButton} compareBtn btnBlock btnOutline btnDefault`}
          >{intl.formatMessage({id:'PartnerPage.HomeCareButtonValue'})}</button>
        </div>
        <div className={css.partnerImageBlock}>
            <div className={css.partnerImage}>
                     <img src={image} alt="partner"/>
            </div>
        </div>

    </div>
        // <div className="row">
        //     <div className="column">
        //         <div className={'exp-vector-container'}>
        //             <img src={union} alt={'union'} height={50} width={50}/>
        //         </div>
        //         <div className={'exp-vector-icon'}>
        //             <img src={icon} alt={'icon'} height={50} width={50}/>
        //         </div>
        //         <div>
        //             <div>Home Care Partners</div>
        //             <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque at porttitor metus, ut eleifend diam. Aliquam at vulputate justo. Nullam dictum facilisis est vel sollicitudin. Nulla sagittis, nunc ut pharetra mollis, risus nulla maximus risus, non condimentum magna turpis id metus. Donec ultrices viverra pellentesque.</p>
        //             <div>
        //                 {arr?.map((val, index) => {
        //                     return (
        //                         <div key={index}>
        //                         <div className="total-partnership">
        //                             {val?.total}
        //                         </div>
        //                         <div className="title-partnership">{val?.title}</div>
        //                         </div>
        //                     );
        //                 })}
        //             </div>

        //             <ButtonComponent value='Become a Home Care Partner' variant='secondary' />

        //         </div>
        //     </div>
        //     <div className="column">
        //         <img src={image} alt="partner" height={300} width={100}/>
        //     </div>

        // </div>
    )

}

export default HomeCareSection
