import React, { useState } from 'react';
import { array, bool, node, object, string } from 'prop-types';
import classNames from 'classnames';

import { propTypes } from '../../../util/types';
import { PaginationLinks } from '../../../components';
import ListingCard from '../../../components/SearchListingCard/ListingCard';

import css from './SearchResultsPanel.module.css';
import ButtonComponent from '../../LandingPageCustom/ButtonComponent/ButtonComponent';
import ComparePopUp from './ComparePopUp/ComparePopUp';

const SearchResultsPanel = props => {
  const {
    className,
    rootClassName,
    listings,
    pagination,
    search,
    setActiveListing,
    isMapVariant,
    listingView,
    onManageDisableScrolling,
    intl,
    config,
  } = props;
  const [selectedListings, setSelectedListings] = useState([]);
  const [showComparePopup, setShowComparePopup] = useState(false);

  const classes = classNames(rootClassName || css.root, className);

  const paginationLinks =
    pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName="SearchPage"
        pageSearchParams={search}
        pagination={pagination}
      />
    ) : null;

  const cardRenderSizes = isMapVariant => {
    if (isMapVariant) {
      // Panel width relative to the viewport
      const panelMediumWidth = 50;
      const panelLargeWidth = 62.5;
      return [
        '(max-width: 767px) 100vw',
        `(max-width: 1023px) ${panelMediumWidth}vw`,
        `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
        `${panelLargeWidth / 3}vw`,
      ].join(', ');
    } else {
      // Panel width relative to the viewport
      const panelMediumWidth = 50;
      const panelLargeWidth = 62.5;
      return [
        '(max-width: 549px) 100vw',
        '(max-width: 767px) 50vw',
        `(max-width: 1439px) 26vw`,
        `(max-width: 1920px) 18vw`,
        `14vw`,
      ].join(', ');
    }
  };

  const handleCompare = listing => {
    setSelectedListings(prev => {
      const isAlreadySelected = prev.some(item => item.id === listing.id);
      if (isAlreadySelected) {
        return prev.filter(item => item.id !== listing.id);
      }
      if (prev.length < 3) {
        return [...prev, { id: listing.id, title: listing.title, address: listing.address }];
      }
      // alert('You can only compare up to 3 listings.');
      return prev;
    });
  };

  return (
    <div className={classNames(classes)}>
      <div
        className={classNames(`${isMapVariant ? css.listingCardsMapVariant : css.listingCards}`, {
          [css.listView]: listingView,
        })}
      >
        {listings.map(l => (
          <ListingCard
            className={css.listingCard}
            listingView={listingView}
            key={l.id.uuid}
            listing={l}
            renderSizes={cardRenderSizes(isMapVariant)}
            setActiveListing={setActiveListing}
            onCompare={handleCompare}
            selectedListings={selectedListings}
          />
        ))}
        {props.children}
      </div>
      {paginationLinks}
      {selectedListings?.length > 0 && (
        <div className={css.compareDiv}>
          <span>
            {selectedListings?.length > 1
              ? `Compare ${selectedListings?.length} of 3`
              : 'Add another listing to compare'}{' '}
          </span>
          <div className={css.cambareCards}>
            {selectedListings.map(listing => (
              <div key={listing.id} className={css.selectedListingsItems}>
                <p>
                  <strong>{listing.title}</strong>
                </p>
                <p>{listing.address}</p>
                <button className={css.removeIcon} onClick={() => handleCompare(listing)}>
                  ✖
                </button>
              </div>
            ))}
          </div>
          <ButtonComponent
            value="Compare"
            variant="secondary"
            onClick={() => setShowComparePopup(true)}
            disabled={selectedListings.length < 2}
          />
        </div>
      )}

      <ComparePopUp
        showComparePopup={showComparePopup}
        setShowComparePopup={setShowComparePopup}
        onManageDisableScrolling={onManageDisableScrolling}
        setSelectedListings={setSelectedListings}
        selectedListings={selectedListings}
        config={config}
        intl={intl}
      />
    </div>
  );
};

SearchResultsPanel.defaultProps = {
  children: null,
  className: null,
  listings: [],
  pagination: null,
  rootClassName: null,
  search: null,
  isMapVariant: true,
};

SearchResultsPanel.propTypes = {
  children: node,
  className: string,
  listings: array,
  pagination: propTypes.pagination,
  rootClassName: string,
  search: object,
  isMapVariant: bool,
};

export default SearchResultsPanel;
