import React from 'react'
import css from './SearchInput.module.css'
import IconMap from '../../../../components/IconMap/IconMap'
import IconCalender from '../../../../components/IconCalender/IconCalender'
import ButtonComponent from '../../ButtonComponent/ButtonComponent'
const SearchInput = () => {
  return (
    <>
         <div className={css.wrapperSearch}>
                          <div className={css.searchinput}>
                              <div className={css.formField}>
                                <span className={css.icon}>
                                  <IconMap/>
                                </span>
                                <input type='text' value={''} placeholder='Search location'/>
                              </div>
                              <div className={css.formField}>
                                <span className={css.icon}>
                                <IconCalender/>
                                </span>
                                <input type='date' value={''} placeholder='Move in date...'/>
                              </div>
                             <ButtonComponent value='Search' type='submit' customClass="customClass" variant="primary"/>
                        </div>
                        </div>
    </>
  )
}





export default SearchInput;
