import React from 'react'
import './PageTitleStyle.module.css'
const PageTitle = ({title, hightlightText, subLine, subLineIcon, className}) => {
  return (
    <>
    
      <h2>{title} <span>{hightlightText}</span></h2> 
      <p className={className}>{subLineIcon} {subLine}</p>      
    </>
  )
}

const PageTitleRev = ({title, hightlightText, subLine, subLineIcon, className}) => {
  return (
    <>
    
      <h2 style={{fontSize:"40px"}}><span>{hightlightText}</span> {title} </h2> 
      <p className={className}>{subLineIcon} {subLine}</p>      
    </>
  )
}

export  {PageTitle, PageTitleRev}
