import React from 'react';
import blog from '../Assets/research-image.png';
import blog1 from '../Assets/research-image1.png';
import blog2 from '../Assets/research-image2.png';
import vector from '../Assets/Vector.svg';
import vector1 from '../Assets/Vector1.svg';
import { useIntl } from 'react-intl';
// import css from '../../SearchPage/SearchResultsPanel/SearchResultsPanel.module.css';
import { PaginationLinks } from '../../../components'
import css from './Research.module.css'

const ResearchCard = ({category, date, title, description, image }) => {
  const intl = useIntl();
  return (
        <div className={css.recentArticleCard}>
          <div className={css.recentArticleImage}>
            <img src={image} alt={title} height={300} width={100}/>
          </div>
          <div className={css.recentArticleContent}>
            <div className={css.recentArticleMeta}>
              <div className={css.dateCat}>
                <img src={vector} alt={title} height={100} width={150}/>
              <p>{date} </p>
              </div>
              <div className={css.dateCat}>
              <img src={vector1} alt={title} height={100} width={150}/>
                <p>{category}</p>
              </div>

            </div>
            <div className={css.articleDescription}>
                <p className={css.recentArticleTitle}>{title}</p>
                <p className={css.recentArticleDescription}>{description}</p>
                </div>
            <button className={css.ReadMore} >
                {intl.formatMessage({ id: 'BlogPage.ViewMoreButton' })}
                <span className='iconArrowRight'></span>
            </button>
          </div>
        </div>
  );
};

const ResearchSection = () => {
  const intl = useIntl();
  const ResearchTitle = intl.formatMessage({ id: 'BlogPage.ResearchTitle' });
  const ResearchDesc = intl.formatMessage({ id: 'BlogPage.ResearchDesc' });
  const ResearchDataCategorySection = intl.formatMessage({ id: 'BlogPage.ResearchDataCategorySection' });
  const ResearchDataTitleSection = intl.formatMessage({ id: 'BlogPage.ResearchDataTitleSection' });
  const ResearchDataDescriptionSection = intl.formatMessage({ id: 'BlogPage.ResearchDataDescriptionSection' });
  const ResearchDataCategorySection1 = intl.formatMessage({ id: 'BlogPage.ResearchDataCategorySection1' });
  const ResearchDataTitleSection1 = intl.formatMessage({ id: 'BlogPage.ResearchDataTitleSection1' });
  const ResearchDataDescriptionSection1 = intl.formatMessage({ id: 'BlogPage.ResearchDataDescriptionSection1' });
  const ResearchDataCategorySection2 = intl.formatMessage({ id: 'BlogPage.ResearchDataCategorySection2' });
  const ResearchDataTitleSection2 = intl.formatMessage({ id: 'BlogPage.ResearchDataTitleSection2' });
  const ResearchDataDescriptionSection2 = intl.formatMessage({ id: 'BlogPage.ResearchDataDescriptionSection2' });

  const ResearchData = [
    {
      category: ResearchDataCategorySection,
      date: '18 June 2024',
      title: ResearchDataTitleSection,
      description: ResearchDataDescriptionSection,
      image: blog,
    },
    {
      category: ResearchDataCategorySection1,
      date: '11 June 2024',
      title: ResearchDataTitleSection1,
      description: ResearchDataDescriptionSection1,
      image: blog1,
    },
    {
      category: ResearchDataCategorySection2,
      date: '18 March 2024',
      title: ResearchDataTitleSection2,
      description:ResearchDataDescriptionSection2,
      image: blog2,
    },
  ];

  const paginationLinks =  <PaginationLinks
    className={css.pagination}
    pageName="BlogPage"
    pageSearchParams={{page:1}}
    pagination={10}
  />

  return (
    <section className={css.articleCardBlocks}>
       <div className={css.articlesHeading}>
       <h4>{ResearchTitle}</h4>
           <p>{ResearchDesc}</p>
      </div>
       <div className={css.articlesCardsBlock}>
            {ResearchData.map((blog, index) => (
              <ResearchCard
                key={index}
                category={blog.category}
                date={blog.date}
                title={blog.title}
                description={blog.description}
                image={blog.image}
              />
            ))}
      </div>
      <div className={css.paginationBlock}>
         {paginationLinks}
      </div>

    </section>
  );
};

export default ResearchSection;
