import React from 'react';
import css from './CareAndConnectionSection.module.css'; // Import the CSS module
import HeadingComponent from '../HeadingBlockComp/HeadingComponent';
import ButtonComponent from '../ButtonComponent/ButtonComponent';
import IconTiltArrow from '../../../components/IconTiltArrow/IconTiltArrow';
import { useIntl } from 'react-intl';

const CareAndConnectionSection = () => {
  const intl = useIntl();

  return (
    <section className={css.careConnectionSection}>
      <div className={css.titleSection}>
        <HeadingComponent
          title={intl.formatMessage({ id: 'CareAndConnectionSection.Title' })}
          subTitle={intl.formatMessage({ id: 'CareAndConnectionSection.SubTitle' })}
          isCustomClass={true}
        />
      </div>

      <div className={css.buttonSection}>
        <ButtonComponent
          variant="primary"
          value={intl.formatMessage({ id: 'CareAndConnectionSection.ButtonValue' })}
          icon={<IconTiltArrow />}
        />
      </div>
    </section>
  );
};

export default CareAndConnectionSection;
