
import { userAskQuestionApi } from '../../util/api';
import { storableError } from '../../util/errors';

// ================ Action types ================ //

export const USER_QUESTION_REQUEST = 'app/PartnerPage/USER_QUESTION_REQUEST';
// export const RECOVERY_SUCCESS = 'app/PasswordRecoveryPage/RECOVERY_SUCCESS';
// export const RECOVERY_ERROR = 'app/PasswordRecoveryPage/RECOVERY_ERROR';
// export const RETYPE_EMAIL = 'app/PasswordRecoveryPage/RETYPE_EMAIL';
// export const CLEAR_RECOVERY_ERROR = 'app/PasswordRecoveryPage/CLEAR_RECOVERY_ERROR';

// ================ Reducer ================ //

const initialState = {
  // initialEmail: null,
  submitedQuestion: null,
  SubmitQuesError: null,
  submitQuesInProgress: false,
  submitQuesRequested: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case USER_QUESTION_REQUEST:
      return {
        ...state,
        // submittedEmail: null,
        submitQuesInProgress: true,
        SubmitQuesError: null,
      };
    case RECOVERY_SUCCESS:
      return {
        ...state,
        submitedQuestion: payload,
        // initialEmail: payload,
        submitQuesInProgress: false,
        submitQuesRequested: true,
      };
    case RECOVERY_ERROR:
      return {
        ...state,
        submitQuesInProgress: false,
        SubmitQuesError: payload.error,
        // initialEmail: payload.email,
      };
    // case RETYPE_EMAIL:
    //   return {
    //     ...state,
    //     initialEmail: state.submittedEmail,
    //     submittedEmail: null,
    //     passwordRequested: false,
    //   };
    // case CLEAR_RECOVERY_ERROR:
    //   return { ...state, recoveryError: null };
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const questionSubmitRequest = () => ({ type: USER_QUESTION_REQUEST });
export const passwordRecoverySuccess = email => ({ type: RECOVERY_SUCCESS, payload: { email } });
// export const passwordRecoveryError = (error, email) => ({
//   type: RECOVERY_ERROR,
//   payload: { error, email },
//   error: true,
// });
// export const retypePasswordRecoveryEmail = () => ({ type: RETYPE_EMAIL });
// export const clearPasswordRecoveryError = () => ({ type: CLEAR_RECOVERY_ERROR });

// ================ Thunks ================ //


// export const questionSubmit = values => (dispatch, getState, sdk) => {
//   console.log("values",values)
//   dispatch(questionSubmitRequest());

// //   return sdk.passwordReset
// //     .request({ email })
// //     .then(() => dispatch(passwordRecoverySuccess(email)))
// //     .catch(e => dispatch(passwordRecoveryError(storableError(e), email)));
// };


export const questionSubmit = (data) => (dispatch, getState, sdk) => {
  return userAskQuestionApi(data).then(res => {
    return res;
  })
}
