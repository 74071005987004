import React from 'react';

const IconFire = () => {
  return (
    <>
      <svg
        width="20"
        height="22"
        viewBox="0 0 20 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 0C9.48295 0 9.05116 0.394163 9.00414 0.909074L8.25859 9.07467C8.22197 9.47569 7.75398 9.67494 7.43953 9.42338C7.37028 9.36798 7.31683 9.29534 7.28454 9.21275L6.35319 9.57687L7.28454 9.21275L5.09888 3.6222C4.97153 3.29648 4.68328 3.06095 4.33871 3.00108C3.99414 2.94121 3.64333 3.06571 3.41358 3.32938C1.32015 5.73195 0 9.13138 0 12.4286C0 15.4508 1.11157 17.8739 2.97045 19.5317C4.8144 21.1762 7.3159 22 10 22C12.6841 22 15.1856 21.1762 17.0295 19.5317C18.8884 17.8739 20 15.4508 20 12.4286C20 9.28531 18.0319 6.19063 15.9732 3.96429C14.9252 2.83097 13.8055 1.86962 12.8019 1.18353C12.3007 0.840888 11.8129 0.556221 11.3647 0.353445C10.9391 0.1609 10.4588 0 10 0Z"
          fill="#FC695F"
        />
      </svg>
    </>
  );
};

export default IconFire;
