import React from 'react';

const LivingIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24">
      <path
        opacity="0.4"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1Z"
        fill="currentColor"
      />
      <path
        d="M18.9204 3.07192C18.9604 3.12456 19.0396 3.12456 19.0796 3.07192L19.4699 2.55859C20.103 1.72573 21.3665 1.84415 21.8413 2.78083C22.1091 3.3092 22.0332 3.95144 21.6501 4.39936L19.7064 6.67206C19.3324 7.10931 18.6676 7.10931 18.2936 6.67206L16.3499 4.39936C15.9668 3.95145 15.8909 3.3092 16.1587 2.78083C16.6335 1.84415 17.897 1.72574 18.5301 2.55859L18.9204 3.07192Z"
        fill="currentColor"
      />
      <path
        d="M5.29285 10.2929L7.29285 8.29294C7.68337 7.90242 8.31654 7.90242 8.70706 8.29294L10.7071 10.2929L9.29285 11.7072L7.99995 10.4143L6.70706 11.7072L5.29285 10.2929Z"
        fill="currentColor"
      />
      <path
        d="M14.2 14.4C13.1 15.8667 10.9 15.8667 9.79995 14.4L8.19995 15.6C10.1 18.1333 13.9 18.1333 15.8 15.6L14.2 14.4Z"
        fill="currentColor"
      />
      <path
        d="M16.7072 8.29294L18.7072 10.2929L17.2929 11.7072L16 10.4143L14.7072 11.7072L13.2929 10.2929L15.2929 8.29294C15.6835 7.90242 16.3166 7.90242 16.7072 8.29294Z"
        fill="currentColor"
      />
      <path
        d="M5.07961 18.0719C5.03959 18.1246 4.96041 18.1246 4.92039 18.0719L4.53015 17.5586C3.89699 16.7257 2.63346 16.8441 2.15873 17.7808C1.89095 18.3092 1.96679 18.9514 2.34987 19.3994L4.29361 21.6721C4.66757 22.1093 5.33243 22.1093 5.70639 21.6721L7.65013 19.3994C8.03321 18.9514 8.10905 18.3092 7.84127 17.7808C7.36654 16.8441 6.10301 16.7257 5.46985 17.5586L5.07961 18.0719Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default LivingIcon;
