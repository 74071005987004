import React from 'react'
import css from './CustomeFilterStyle.module.css'
const CustomCheckBox = (props) => {
  return (
    <>
      <div className={css.checkBox}>
       <input type='checkbox' {...props}/>  
      </div>
    </>
  )
}

export default CustomCheckBox
